<template>
  <div class="order-create__col custom-col mb-0"
       v-if="isRecipientTaxValue">

    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
                        'common_recipientTaxValue',
                      ])"></div>
      {{$t('common_recipientTaxValue.localization_value.value')}}
    </div>

    <div class="custom-row">
      <div class="order-create__product-col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
                  'common_recipientTaxValue',
                ])"></div>

        <DefaultInput
            :label="'Mexican Tax ID / National ID'"
            v-bind:class="{'ui-no-valid': ex.validation.recipientTax}"
            :error="ex.validation.recipientTax"
            :errorTxt="$t(`${ex.validationTranslate.recipientTax}.localization_value.value`)"
            :type="'text'"
            :autocompleteOff="true"
            :maxLength="35"
            :value="ex.express.recipientTax"
            @change="(val) => changeTax(val)"
        />
      </div>
    </div>

</div>

</template>

<script>
import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
import {MEXICO_COUNTRY_ID} from "@/staticData/staticVariables";
export default {
name: "TaxBlock",
  components: {DefaultInput},

  props: {
    ex: Object,
  },

  computed: {
    isRecipientTaxValue() {
      return this.ex.express.recipientNeedTax
    },

    country() {
      return this.ex.express.delivery.delivery.country?.id
    },
  },

  watch: {
    country(newVal) {
      console.log(23323232);
      console.log(newVal);
      if(newVal && newVal === MEXICO_COUNTRY_ID) {
        this.ex.express.recipientNeedTax = true
      } else {
        this.ex.express.recipientNeedTax = false
      }
    },
  },

  methods: {
    changeTax(val) {
      this.ex.express.recipientTax = val.replace(/\s+/g, '')
    }
  }

}
</script>

<style scoped>

</style>