import { render, staticRenderFns } from "./PromoCode.vue?vue&type=template&id=10e7d66d&scoped=true"
import script from "./PromoCode.vue?vue&type=script&lang=js"
export * from "./PromoCode.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10e7d66d",
  null
  
)

export default component.exports