<template>
  <div class="fragment">
    <ExpressOrderTableUser
            :navTabs="navTabs"
            :countFilterParams="countFilterParams"
            :filterGetParams="filterGetParams"
            @changeTab="changeTab"
            @resetFilter="resetFilter"
            @changeFilter="changeFilter"
            @showMore="showMore"
            @reload="reload"
            @downloadFiles="downloadFiles"
            @thermalPrint="thermalPrint"
    />
  </div>
</template>

<script>
  import ExpressOrderTableUser from "./ExpressOrderTableUser/ExpressOrderTableUser.vue";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
  import {
    DHL_DELIVERY_SERVICE,
    FEDEX_DELIVERY_SERVICE, NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE,
    TNT_DELIVERY_SERVICE, UPS_DELIVERY_SERVICE
  } from "../../../../../staticData/staticVariables";

  export default {
    name: "ExpressOrderTable",

    components: {
      ExpressOrderTableUser,
    },

    mixins: [routeFilter],

    computed: {
      router: function() {
        return this.$route.query
      }
    },

    watch: {
      router: function(newRoute) {
        if(Object.keys(newRoute).length === 0){
          this.changeTab('all')
        }
      },

      loadUserAuthorizedData: function () {
        // set translate tab by user country
        // this.allOrdersTabTranslateByUserType()
        // set tab active and visible by permissions
        if(this.downloadPermissions){
          this.checkTabPermission()
        }
        // set tab active by user country group
        this.firstActiveTabByUserType()
      },

      downloadPermissions() {
        this.checkTabPermission()
      }
    },

    data() {
      return {
        forPage: this.$store.getters.getExpressForPage,
        page: 1,
        onePage: 1,
        filterType: '',
        filterDate: [],
        filterUserName: '',
        filterUserId: '',
        filterId: '',
        filterContactName: '',
        filterDeliveryDate: [],
        filterItemName: '',
        filterEmail: '',
        filterTransactionNumber: '',
        filterTrackingNumber: '',
        filterExpressTrack: '',
        filterTrackingNumberNPoshta: '',
        filterPaymentStatus: '',
        filterCountryId: '',
        filterSendStatus: '',

        countFilterParams: 0,
        filterGetParams: {},


        navTabs: {
          all: {
            active: true,
            label: this.$t('express_allOrders.localization_value.value'),
            subLabel: this.$t('express_allOrdersSub.localization_value.value'),
            name: 'all',
            id: false,
            permissionVisible: true,
          },
          [DHL_DELIVERY_SERVICE.name]: {
            active: false,
            label: this.$t('express_DHL.localization_value.value'),
            subLabel: this.$t('express_DHLSub.localization_value.value'),
            ico: '/img/company-icons-group/tab-dhl.svg',
            icoWidth: '105',
            name: DHL_DELIVERY_SERVICE.name,
            id: DHL_DELIVERY_SERVICE.id,
            permissionVisible: false,
          },
          [FEDEX_DELIVERY_SERVICE.name]: {
            active: false,
            label: this.$t('express_fedex.localization_value.value'),
            subLabel: this.$t('express_fedexSub.localization_value.value'),
            ico: '/img/company-icons-group/tab-fedex.svg',
            icoWidth: '80',
            name: FEDEX_DELIVERY_SERVICE.name,
            id: FEDEX_DELIVERY_SERVICE.id,
            permissionVisible: false,
          },
          [NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.name]: {
            active: false,
            label: this.$t('express_poshta.localization_value.value'),
            subLabel: this.$t('express_poshtaSub.localization_value.value'),
            ico: '/img/company-icons-group/logo-poshta-global.svg',
            icoWidth: '170',
            name: NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.name,
            id: NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id,
            permissionVisible: false,
          },
          [UPS_DELIVERY_SERVICE.name]: {
            active: false,
            label: this.$t('express_ups.localization_value.value'),
            subLabel: this.$t('express_upsSub.localization_value.value'),
            ico: '/img/company-icons-group/ups.svg',
            icoWidth: '20',
            name: UPS_DELIVERY_SERVICE.name,
            id: UPS_DELIVERY_SERVICE.id,
            permissionVisible: false,
          },
          // [TNT_DELIVERY_SERVICE.name]: {
          //   active: false,
          //   label: this.$t('express_tnt.localization_value.value'),
          //   subLabel: this.$t('express_tntSub.localization_value.value'),
          //   ico: '/img/company-icons-group/tab-tnt.svg',
          //   icoWidth: '55',
          //   name: TNT_DELIVERY_SERVICE.name,
          //   id: TNT_DELIVERY_SERVICE.id,
          //   permissionVisible: false,
          // },
        },

      }
    },

    mounted() {

      if(this.isUserHasEasyOrder) {
        this.$router.push(this.$store.getters.GET_PATHS.easyOrders)
        return
      }

      console.log('test cache888 with headers');

      if(this.$route.query.type !== undefined){
        this.navTabs.all.active = false
        this.navTabs[this.$route.query.type].active = true
      }

      if(this.loadUserAuthorizedData){
        // set translate tab by user country
        // this.allOrdersTabTranslateByUserType()

        // set tab active by user country group
        this.firstActiveTabByUserType()

        // set tab active and visible by permissions
        if(this.downloadPermissions){
          this.checkTabPermission()
        }
      }

    },

    methods: {

      checkTabPermission() {

        this.activeTabByPermissions('navTabs', {
          [DHL_DELIVERY_SERVICE.name]: this.PERMISSIONS.ORDER_EXPRESS_DHL,
          [FEDEX_DELIVERY_SERVICE.name]: this.PERMISSIONS.ORDER_EXPRESS_FEDEX,
          [NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.name]: this.PERMISSIONS.ORDER_EXPRESS_NPG,
          [UPS_DELIVERY_SERVICE.name]: this.PERMISSIONS.ORDER_EXPRESS_UPS,
          // [TNT_DELIVERY_SERVICE.name]: this.PERMISSIONS.ORDER_EXPRESS_TNT,
        })

        // if(this.isPoland) {
        //   this.navTabs[DHL_DELIVERY_SERVICE.name].permissionVisible = false
        // }

        /**
         * if NOVAPOSHTA GLOBAL and No From Ukraine -> permissionVisible = false
         */
        if(this.navTabs[NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.name].permissionVisible &&
            !this.isAdmin && !this.isUserUkraineOrAlternativeContactUkraine) {
          this.navTabs[NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.name].permissionVisible = false
        }

        this.hideTabAllIfVisibleOnlyOne('navTabs', 'all', ['permissionVisible'])

        if(!this._.find(this.navTabs, {active: true}).permissionVisible){
          this.setActivePermissionVisibleTab('navTabs')
          return
        }

        this.filter()
      },

      firstActiveTabByUserType(){

        if(Object.keys(this.$route.query).length > 0) return false

        this.navTabs.all.active = (!this.isSNGGroup || this.isAdmin)

        this.navTabs[FEDEX_DELIVERY_SERVICE.name].active = !this.navTabs.all.active

        return true
      },

      // allOrdersTabTranslateByUserType(){
      //   this.navTabs.all.subLabel = this.isUkrainian ? this.$t('express_allOrdersSub.localization_value.value') :
      //     this.$t('express_allOrdersSubBelarus.localization_value.value')
      // },

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          date: this.$route.query.date,
          type: this.$route.query.type,
          userName: this.$route.query.userName,
          userId: this.$route.query.userId,

          id: this.$route.query.id,
          contactName: this.$route.query.contactName,
          deliveryDate: this.$route.query.deliveryDate,
          email: this.$route.query.email,
          itemName: this.$route.query.itemName,
          transactionNumber: this.$route.query.transactionNumber,
          trackingNumber: this.$route.query.trackingNumber,
          expressTrack: this.$route.query.expressTrack,
          trackingNumberNPoshta: this.$route.query.trackingNumberNPoshta,
          paymentStatus: this.$route.query.paymentStatus,
          countryId: this.$route.query.countryId,
          sendStatus: this.$route.query.sendStatus,
        }
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
          generateMainFilter.page,
          generateMainFilter.forPage
        )

        this.$store.commit('setExpressFilter', window.location.search)

        if (next) this.$store.commit('setNextExpressPage', true)
        this.$store.dispatch('fetchExpress', {filter: url}).then(() => {
          this.$store.commit('setNextExpressPage', false)
        })


        this.checkCountFilter(['userName', 'type'])
      },

      generateFilterUrl(page, forPage, exportIds = false) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        this.generateFilterQueryParams(
          myQuery,
          {
            filterId: 'OrderExpressId',
            filterContactName: 'OrderExpressPaymentRecipientContactName',
            filterEmail: 'OrderExpressPaymentRecipientEmail',
            filterTransactionNumber: 'transactionId',
            filterTrackingNumber: 'OrderExpressPaymentTrackingNumber',
            filterExpressTrack: 'OrderExpressExternalNumber',
            filterTrackingNumberNPoshta: 'OrderExpressNewPostTrackingNumber',
            filterPaymentStatus: 'OrderExpressPaymentStatus',
            filterCountryId: 'OrderExpressPaymentRecipientCountryId',
            filterUserId: 'OrderExpressPaymentUserId',
            filterItemName: 'proform_entity_naming',
            filterSendStatus: 'document_confirmation_payload',
          },
        )

        let deliveryTypeId = ''
        switch (this.filterType) {
          case DHL_DELIVERY_SERVICE.name:
            deliveryTypeId = DHL_DELIVERY_SERVICE.id
            break
          case FEDEX_DELIVERY_SERVICE.name:
            deliveryTypeId = FEDEX_DELIVERY_SERVICE.id
            break
          case TNT_DELIVERY_SERVICE.name:
            deliveryTypeId = TNT_DELIVERY_SERVICE.id
            break
          case NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.name:
            deliveryTypeId = NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id
            break
          case UPS_DELIVERY_SERVICE.name:
            deliveryTypeId = UPS_DELIVERY_SERVICE.id
            break
        }
        if (deliveryTypeId !== '')
          myQuery.where('OrderExpressPaymentDeliveryServiceId', deliveryTypeId)


        // this.filterDate = this.$route.query.date !== undefined ? this.$route.query.date : ''
        if (this.filterDate.length > 0) {
          let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')

          myQuery.whereIn(
            'between_created_at',
            date
          )
        }
        if (this.filterDeliveryDate.length > 0) {
          let deliveryDate = this.generateDateForFilter(this.filterDeliveryDate, 'YYYY-MM-DD')

          myQuery.whereIn(
            'between_delivery_date',
            deliveryDate
          )
        }

        // If generate on export file
        if(exportIds && exportIds.length > 0) {
          myQuery.whereIn('OrderExpressId', exportIds)
        }

        // myQuery.includes('trackingLog')

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

      downloadFiles(type) {
        let forPage, page, url, exportIds

        exportIds = this.getCheckedRows('row-name')

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(false, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        url = this.generateFilterUrl(page, forPage, exportIds)

        this.exportTableFiles(url, type, 'getExportExpress', 'Express')

        // this.$store.dispatch('getExportExpress', {filter: url, exportType: type}).then((response) => {
        //   if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
        //
        //     this.createDownloadLink({data: response.data, name: `Express.${type}`, type: type})
        //   } else {
        //     setTimeout(() => {
        //       this.openNotify('error', 'common_notificationUndefinedError')
        //     }, 200)
        //   }
        // })

      },

      thermalPrint(data) {

        if (!data.id) return

        let routeData = this.$router.resolve({
          path: this.$store.getters.GET_PATHS.generatePdfFile + '/express-order-thermal-print',
          query: {
            id: data.id,
            type: 'pdf'
          }
        })

        window.open(routeData.href, '_blank');

      },
    },

    destroyed() {
      setTimeout(() => {
        this.$store.commit('setExpress', {express: []})
        this.$store.commit('setExpressCommonList', {expressCommonList: []})
      }, 300)
    },

  }
</script>

<style scoped>

</style>
