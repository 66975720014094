import { render, staticRenderFns } from "./PickupInfo.vue?vue&type=template&id=6a0f8590&scoped=true"
import script from "./PickupInfo.vue?vue&type=script&lang=js"
export * from "./PickupInfo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a0f8590",
  null
  
)

export default component.exports