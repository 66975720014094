<template>
  <div class="fragment">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
        'common_promoCodes',
        ])"></div>
      {{$t('common_promoCodes.localization_value.value')}}
    </div>

    <div class="order-create__row custom-row"
         v-for="(code, index) in ex.express.promoCodes"
         :key="index"
    >
      <div class="order-create__col custom-col custom-col--33 custom-col--md-33 custom-col--sm-50">
        <DefaultInput
            :label="`${$t('consolidations_promoCode.localization_value.value')} ${index !== 0 ? index + 1 : ''}`"
            :type="'text'"
            v-bind:class="{'ui-no-valid': code.validation.code}"
            :errorTxt="$t(`${code.validationTranslate.code}.localization_value.value`)"
            :error="code.validation.code"
            v-model="code.data.code"
        />
      </div>
      <div class="order-create__col custom-col custom-col--33 custom-col--md-33 custom-col--sm-50" v-if="index !== 0">
        <div class="order-create__product-btn-i site-link" style="margin-top: 13px;"
             @click="removePromoCode(index)">
          {{$t('common_removePromoCode.localization_value.value')}}
        </div>
      </div>
    </div>

<!--    <div v-if="ex.data.promoCodes.length < 2"-->
<!--        class="order-create__product-btn-i mb-4 site-link "-->
<!--        @click="addPromoCode">-->
<!--        + {{$t('common_AddPromoCode.localization_value.value')}}-->
<!--    </div>-->
    <div class="order-create__product-btn-i mb-4 site-link "
        @click="checkPromoCode">
        {{$t('common_checkPromoCode.localization_value.value')}}
    </div>
  </div>
</template>

<script>
import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
// import {consolidationMixinHelper} from "@/mixins/consolidationMixins/consolidationMixinHelper";
export default {
  name: "PromoCode",
  components: {DefaultInput},

  // mixins: [consolidationMixinHelper],

  props: {
    ex: {
      type: Object,
    }
  },

  data() {
    return {

    }
  },

  methods: {
    addPromoCode() {
      this.ex.addPromoCode()
    },

    removePromoCode(index) {
      this.ex.removePromoCode(index)
    },

    checkPromoCode() {
      let validate = true

      this.ex.express.promoCodes.map(code => {
        validate = code.firstValidate({id: false, code: true})
      })

      if(!validate) {
        return
      }

      let data = this.ex.checkPromoCodePrepareData()

      console.log(data);

      this.$store.dispatch('checkExpressPromoCode', data).then(response => {
        switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

            this.openNotify('success', 'common_notificationRecordChanged')
            break
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
            /**
             * Undefined Error
             */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      }).catch(error => this.createErrorLog(error))
    },
  },

}
</script>

<style scoped>

</style>