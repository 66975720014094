<template>
	<ExpressOrderShowUser
					:ex="ex"
          v-if="finishLoading"
	/>
</template>

<script>
	import ExpressOrderShowUser from "./ExpressOrderShowUser/ExpressOrderShowUser";
	import {proformMixins} from "../../../../../mixins/proformMixins/proformMixins";
	import {Express} from "../../models/Express";
	import {expressMixinsFromCard} from "../../../../../mixins/expressMixins/expressMixin";

	export default {
		name: "ExpressOrderShow",

		components: {
      ExpressOrderShowUser
		},

		mixins: [proformMixins, expressMixinsFromCard],

    props: {
      easyOrder: {
        type: Boolean,
        default: false,
      }
    },

		watch: {
			downloadPermissions() {
				this.checkPermission()
			},
		},

		data() {
			return {
				ex: new Express(),
        finishLoading: false,
			}
		},


		created() {

      if(this.easyOrder) {
        this.ex.express.easyOrderType = true
      }

			if(this.downloadPermissions){
				if(!this.checkPermission()) return
			}

			this.ex.setType(this.$route.params.type)

			// initialize first product
			this.initializeProductProformArray(this.ex.express.products).then(() => {

        let dispatch = 'getExpress'

        if(this.isEasyType) {
          dispatch = 'getEasyOrdersExpress'
        }

				this.$store.dispatch(dispatch, this.$route.params.id).then((response) => {
          let order = this.getRespData(response)

					this.ex.setExpressItem(order, this.isAdmin)

          if (this.ex.express.expressObj.order_addition_documents.length > 0) {
            this.ex.express.expressObj.order_addition_documents.map(file => {
              this.downloadFileFromServer(file).then((dataFile) => {
                this.ex.setExpressAdditionFile(dataFile)
              })
            })
          }

          this.finishLoading = true
				}).catch(error => this.createErrorLog(error))
			}).catch(error => this.createErrorLog(error))
		},

		methods: {

		},

	}
</script>

<style scoped>

</style>
