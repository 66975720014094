import {
  CITY_KIEV,
  DHL_DELIVERY_SERVICE,
  NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE,
  FEDEX_DELIVERY_SERVICE,
  TNT_DELIVERY_SERVICE
} from "../../../../staticData/staticVariables";
import {Express} from "./Express";

Express.prototype.vatNumberValidation = function () {

  if(!this.getVisibleVatNumber()) return true

  let validationItems = {
    vatNumber: this.getVatNumber(),
  }

  let validationOptions = {
    vatNumber: {type: ['empty']}
  }

  return this.checkValid(validationItems, validationOptions)
}

Express.prototype.insuranceValueValidate = function () {

  let sum = 0
  this.express.products.map(item => {
    sum += parseFloat(item.getProductPrice()) * parseInt(item.getProductItemsQuantity())
  })

  return this.express.packaging.insuranceValueValidate(sum)

}

Express.prototype.validateOrderPrice = function () {

  if(this.express.type === DHL_DELIVERY_SERVICE.id && this.express.orderPrice !== ''){
    let totalSum = this.getTotalSumProduct()
    if((parseFloat(this.express.orderPrice)) <= parseFloat(totalSum)){
      this.validation.orderPrice = false
      this.validationTranslate.orderPrice = ''
    } else {
      this.validation.orderPrice = true
      this.validationTranslate.orderPrice = 'express_orderPriceValidate'
      return false
    }
  }

  return true

}

Express.prototype.validateAdditionFiles = function () {
  let valid = true

  if(this.express.type !== FEDEX_DELIVERY_SERVICE.id) {
    return valid
  }

  if(this.express.additionFiles.data.maxCountFiles -
    (this.express.additionFiles.data.files.length + this.express.additionFiles.data.downloadFiles.length) < 0){
    valid = false
  }

  if(this.express.additionFiles.data.files.length > 0 && !this.express.additionFiles.firstValidation()) {
    valid = false
  }

  return valid
}

Express.prototype.validateProducts = function () {
  if(this.express.products.length > 0) {
    this.validation.products = false
    this.validationTranslate.products = ''
  }
  else {
    this.validation.products = true
    this.validationTranslate.products = 'error_required'
    return false
  }

  return true

}

Express.prototype.firstValidationUser = function () {

  let validationItems = {
    user: this.express.userId,
  }

  let validationOptions = {
    user: {type: ['empty']}
  }

  return this.checkValid(validationItems, validationOptions)
}

Express.prototype.firstValid = function () {

  let validationItems = {}
  let validationOptions = {}

  if (this.express.sendType === this.express.sendTypes.warehouse) {
    validationItems = {
      warehouse: this.express.warehouse,
      trackingNumber: this.express.trackingNumber,
    }
    validationOptions = {
      warehouse: {type: ['empty']},
      trackingNumber: {type: ['latin']},
    }
  } else {
    validationItems = {
      senderContactName: this.express.senderContactName,
      senderPhone: this.express.senderPhone,
    }
    validationOptions = {
      senderContactName: {type: ['latin', 'empty']},
      // senderPhone: {type: ['phone', 'empty']},
      senderPhone: {type: ['empty']},
    }
  }
  console.log(this.express.serviceType);
  console.log(this.express.novaPoshtaAddress);
  let valid = true
  if(this.express.type === NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id && this.express.serviceType === this.express.serviceTypes.department && !this.express.novaPoshtaAddress) {
    this.validation.novaPoshtaAddress = true
    this.validationTranslate.novaPoshtaAddress = 'error_required'
    valid = false
  } else {
    this.validation.novaPoshtaAddress = false
    this.validationTranslate.novaPoshtaAddress = ''
  }

  return this.checkValid(validationItems, validationOptions) && valid
}

Express.prototype.deliveryDateValid = function () {

  let valid = true

  let ex = this.express,
    validationItems = {
      deliveryDate: ex.deliveryDate,
    },
    validationOptions = {
      deliveryDate: {type: ['empty']},
    }

  if(ex.type === TNT_DELIVERY_SERVICE.id && ex.sendType === ex.sendTypes.sender){
    validationItems.timeCourierTo = ex.timeCourierTo
    validationItems.timeCourierFrom = ex.timeCourierFrom

    validationOptions.timeCourierTo = {type: ['empty']}
    validationOptions.timeCourierFrom = {type: ['empty']}

    if(ex.lunchBreak){
      validationItems.timeNotAvailableTo = ex.timeNotAvailableTo
      validationItems.timeNotAvailableFrom = ex.timeNotAvailableFrom

      validationOptions.timeNotAvailableTo = {type: ['empty']}
      validationOptions.timeNotAvailableFrom = {type: ['empty']}
    }
  }

  if(ex.type === FEDEX_DELIVERY_SERVICE.id && ex.warehouse?.id === CITY_KIEV.id && ex.visibleVatNumber) {
    valid = ex.vatNumberFile.firstValidation()
  }

  if(ex.recipientNeedTax) {
    validationItems.recipientTax = ex.recipientTax
    validationOptions['recipientTax'] = {type: ['empty']}
  }

  return this.checkValid(validationItems, validationOptions) && valid
}

Express.prototype.freightCostValidate = function () {
  if (!this.express.visibleFreightCost) return true

  let validationItems = {
    freightCost: this.express.freightCost,
  }

  let validationOptions = {
    freightCost: {type: ['numeric', 'not-zero', 'empty']}
  }

  return this.checkValid(validationItems, validationOptions)
}

Express.prototype.deliveryFilesValid = function () {

  if(this.express.filesMaxSizeError) return false

  let ex = this.express,
    files =
      (Object.keys(ex.files).length > 0 ||
        Object.keys(ex.downloadFiles).length > 0) ? 1 : '',

    validationItems = {
      files: files,
    },
    validationOptions = {
      files: {type: ['empty']},
    }

  return this.checkValid(validationItems, validationOptions)
}
