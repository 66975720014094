import {
  CANADA_COUNTRY_ID,
  DHL_DELIVERY_SERVICE,
  FEDEX_DELIVERY_SERVICE,
  NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE,
  POLAND_COUNTRY_ID,
  PYRZOWICE_WAREHOUSE,
  TNT_DELIVERY_SERVICE, UPS_DELIVERY_SERVICE,
  USA_COUNTRY_ID
} from "../../staticData/staticVariables";
import {countriesMixin} from "../commonMixins/countriesMixin";

export const expressCountriesMixin = {

  mixins: [countriesMixin],

  methods: {
    getDeliveryCountries() {
      let filter = ''

      let actualAddressUkraine = this.isUseAlternativeContact ? this.isUserAlternativeContactUkraine : this.isUkrainian

      if(actualAddressUkraine && Number(this.ex.express.type) === FEDEX_DELIVERY_SERVICE.id && this.ex.express.warehouse?.id) {
        filter = '?filter[byWarehouseId]=' + this.ex.express.warehouse?.id
      }

      this.$store.dispatch('fetchCountries', filter).then(() => {
        let countries = this._.toArray(this.$store.getters.getCountries)

        switch (this.ex.express.type) {
          case TNT_DELIVERY_SERVICE.id:
            if(this.getUserTntAllCountriesSetting)
              return this.options = this.getCountriesForRecipient(countries)
            this.options = this.removeCountryById(countries, [
              USA_COUNTRY_ID,
              CANADA_COUNTRY_ID,
            ])
            break
          case DHL_DELIVERY_SERVICE.id:
            this.options = this.getCountriesForRecipient(countries)
            if(this.ex.express.warehouse?.id === PYRZOWICE_WAREHOUSE.id) {
              this.options = this.removeCountryById(this.options, [
                POLAND_COUNTRY_ID,
              ])
            }
            break
          case FEDEX_DELIVERY_SERVICE.id:
            this.options = this.getCountriesForRecipient(countries)
            // this.options = this.removeCountryById(this.options, [
            //   POLAND_COUNTRY_ID,
            // ])
            break
          case NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id:
            this.options = this.getCountriesForRecipient(countries)
            // this.options = this.removeCountryById(this.options, [
            //   POLAND_COUNTRY_ID,
            // ])
            break
          case UPS_DELIVERY_SERVICE.id:
            this.options = this.getCountriesForRecipient(countries)
            // this.options = this.removeCountryById(this.options, [
            //   POLAND_COUNTRY_ID,
            // ])
            break
        }

      })
    }
  }
}
