<template>
  <div>
    <template v-if="!isMobileFunc()">
           <!--v-if="!isWaitForPayStatus(item.status) && (!waitingMakingLabelStatus(item.status) ||-->
                      <!--isAdmin)"-->
      <div class="table-right table-manage-list"
      >

        <LinkButton
            v-if="item.delivery_service_id === DHL_DELIVERY_SERVICE.id && (isPoland || isUserAlternativeContactPoland)"
            :value="getPolandDHLLink.value"
            :type="getPolandDHLLink.type"
            :link="getPolandDHLLink.link"
            :target="getPolandDHLLink.target"
            v-bind:class="{'disabled-btn' : _.has(getPolandDHLLink, 'disabledBtn') && itemLink.disabledBtn}"
            @click.native="getPolandDHLLink.click"
        />

        <ManagerButton
            v-else
            @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton">
          <template v-for="(itemLink, index) in getItemsLink(item)">
            <template v-if="completeArray">
              <template slot="item">
                <LinkButton
                        :key="index"
                        :value="itemLink.value"
                        :type="itemLink.type"
                        :link="itemLink.link"
                        :target="itemLink.target"
                        v-bind:class="{'disabled-btn' : _.has(itemLink, 'disabledBtn') && itemLink.disabledBtn}"
                        @click.native="itemLink.click"
                />
              </template>
            </template>
          </template>
        </ManagerButton>

      </div>
      <!--<div v-else>-->
        <!--<ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton">-->
          <!--<template v-for="(itemLink, index) in getItemsLinkWaitingStatus(item)">-->
            <!--<template v-if="completeArray">-->
              <!--<template slot="item">-->
                <!--<LinkButton-->
                        <!--:key="index"-->
                        <!--:value="itemLink.value"-->
                        <!--:type="itemLink.type"-->
                        <!--:link="itemLink.link"-->
                        <!--@click.native="itemLink.click"-->
                <!--/>-->
              <!--</template>-->
            <!--</template>-->

          <!--</template>-->
        <!--</ManagerButton>-->
      <!--</div>-->
    </template>

    <template v-else>
           <!--v-if="!isWaitForPayStatus(item.status) && (!waitingMakingLabelStatus(item.status) ||-->
                      <!--isAdmin)"-->
      <div class="d-flex flex-wrap mt--10"
      >
        <LinkButton
            v-if="item.delivery_service_id === DHL_DELIVERY_SERVICE.id && (isPoland || isUserAlternativeContactPoland)"
            :value="getPolandDHLLink.value"
            :type="getPolandDHLLink.type"
            :link="getPolandDHLLink.link"
            :target="getPolandDHLLink.target"
            v-bind:class="{'disabled-btn' : _.has(getPolandDHLLink, 'disabledBtn') && itemLink.disabledBtn}"
            @click.native="getPolandDHLLink.click"
        />

        <template v-else>
          <template v-for="(itemLink, index) in getItemsLink(item)">
            <template v-if="completeArray">

              <LinkButton
                  class="mr-3 mt-1"
                  :key="index"
                  :value="itemLink.value"
                  :type="itemLink.type"
                  :link="itemLink.link"
                  @click.native="itemLink.click"
              />
            </template>

          </template>
        </template>

      </div>
      <!--<div v-else>-->
        <!--<ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton">-->
          <!--<template v-for="(itemLink, index) in getItemsLinkWaitingStatus(item)">-->
            <!--<template v-if="completeArray">-->
              <!--<template slot="item">-->
                <!--<LinkButton-->
                        <!--:key="index"-->
                        <!--:value="itemLink.value"-->
                        <!--:type="itemLink.type"-->
                        <!--:link="itemLink.link"-->
                        <!--@click.native="itemLink.click"-->
                <!--/>-->
              <!--</template>-->
            <!--</template>-->

          <!--</template>-->
        <!--</ManagerButton>-->
      <!--</div>-->
    </template>




  </div>
</template>

<script>
  import ManagerButton from "../../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import {expressMixins} from "../../../../../../../../mixins/expressMixins/expressMixin";
  import {
    DHL_DELIVERY_SERVICE, EXPRESS_DELIVERY_STATUSES,
    FEDEX_DELIVERY_SERVICE, NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE, ORDER_TYPE_EXPRESS,
    TNT_DELIVERY_SERVICE, UPS_DELIVERY_SERVICE
  } from "../../../../../../../../staticData/staticVariables";
  import {novaPoshtaOrdersTableMixins} from "../../../../../../../../mixins/internalOrdersMixins/novaPoshtaOrdersMixin";
  import {mixinDetictingMobile} from "../../../../../../../../mixins/mobileFunctions";
  import {cdekGetLabelMixin} from "../../../../../../../../mixins/internalOrdersMixins/сdekOrdersMixin";
  export default {
    name: "ExpressManageButton",
    components: {LinkButton, ManagerButton},

    mixins: [expressMixins, novaPoshtaOrdersTableMixins, mixinDetictingMobile, cdekGetLabelMixin],

    props: {
      item: Object,
      availableCourierTime: Object,
    },


    data() {
      return {
        DHL_DELIVERY_SERVICE: DHL_DELIVERY_SERVICE,
        FEDEX_DELIVERY_SERVICE: FEDEX_DELIVERY_SERVICE,
        TNT_DELIVERY_SERVICE: TNT_DELIVERY_SERVICE,

        EXPRESS_DELIVERY_STATUSES: EXPRESS_DELIVERY_STATUSES,

        ORDER_TYPE_EXPRESS: ORDER_TYPE_EXPRESS,

        completeArray: false,
      }
    },

    methods: {

      // getItemsLinkWaitingStatus(item){
      //   let linksArray = []
      //
      //   if(this.waitingMakingLabelStatus(item.status)){
      //     /**
      //      * Link Copy
      //      */
      //
      //     linksArray.push({
      //       link: '',
      //       value: this.$t('common_copy.localization_value.value'),
      //       type: 'copy',
      //       click: () => {
      //         this.$emit('copyExpress')
      //       }
      //     })
      //   }
      //
      //   linksArray = [...linksArray, ...this.getLinksNovaPoshta(item)]
      //
      //   this.completeArray = true
      //   return linksArray
      // },

      getPolandDHLLink(item) {
        /**
         * Link Docs DHL
         */
        if (this.isCompletedStatus(item.status) && item.user_proform_document_package_file && item.user_proform_document_package_file?.uuid && item.alarm_status !== 1 && (item.order_sender_type === 'by_himself' || this.isAdmin)) {
          return {
            link: this.$store.getters.GET_PATHS.getPdfFile + '/' + item.user_proform_document_package_file?.uuid + '?print=true' + `&orderDHL=${item.id}`,
            value: this.$t('common_DocsDHL.localization_value.value'),
            type: 'docs',
            target: '_blank',
            disabledBtn: !item.enabled_user_proform_document,
            click: () => {
              return false
            }
          }
        }

        return false
      },


      getItemsLink(item){
        let orderType = this.getExpressTypeById(item.delivery_service_id)

        let linksArray = []

        /**
         * Links for type express order
         */
        switch (orderType.id){

            /** DHL LINKS **/
          case DHL_DELIVERY_SERVICE.id:

            /**
             * Link Docs DHL
             */
            if(this.isCompletedStatus(item.status) && item.user_proform_document_package_file && item.user_proform_document_package_file?.uuid && item.alarm_status !== 1 && (item.order_sender_type === 'by_himself' || this.isAdmin)){
              linksArray.push({
                link: this.$store.getters.GET_PATHS.getPdfFile + '/' + item.user_proform_document_package_file?.uuid + '?print=true' + `&orderDHL=${item.id}`,
                value: this.$t('common_DocsDHL.localization_value.value'),
                type: 'docs',
                target: '_blank',
                disabledBtn: !item.enabled_user_proform_document,
                click: () => {
                  return false
                }
              })
            }

            break


            /** TNT LINKS **/
          case TNT_DELIVERY_SERVICE.id:
            /**
             * Link Docs DHL
             */
            if(this.isCompletedStatus(item.status) && (this.isTNTNoTrn || this.isAdmin) && item.user_proform_document_package_file &&
                item.user_proform_document_package_file?.uuid && item.alarm_status !== 1 && (item.order_sender_type === 'by_himself' || this.isAdmin)){
              linksArray.push({
                link: this.$store.getters.GET_PATHS.getPdfFile + '/' + item.user_proform_document_package_file?.uuid + '?print=true' + `&orderTNT=${item.id}`,
                value: this.$t('common_DocsTNT.localization_value.value'),
                type: 'docs',
                target: '_blank',
                disabledBtn: !item.enabled_user_proform_document,
                click: () => {
                  return false
                }
              })
            }

            break

            /** FEDEX LINKS **/
          case FEDEX_DELIVERY_SERVICE.id:
            /**
             * Link Docs FEDEX
             */
            // this.isFedexNoTrn &&
            if(this.isCompletedStatus(item.status) && (!this._.has(item.warehouse, 'id') || this.isAdmin) &&
                item.user_proform_document_package_file && item.user_proform_document_package_file?.uuid  && item.alarm_status !== 1 && (item.order_sender_type === 'by_himself' || this.isAdmin)){
              linksArray.push({
                link: this.$store.getters.GET_PATHS.getPdfFile + '/' + item.user_proform_document_package_file?.uuid + '?print=true' + `&orderFEDEX=${item.id}`,
                value: this.$t('common_DocsFedEx.localization_value.value'),
                type: 'docs',
                target: '_blank',
                disabledBtn: !item.enabled_user_proform_document,
                click: () => {
                  return false
                }
              })
            }

            break

            /** Nova poshta LINKS **/
          case NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id:
            /**
             * Link Docs Nova poshta
             */
            if(this.isCompletedStatus(item.status) &&
                item.user_proform_document_package_file && item.user_proform_document_package_file?.uuid && item.alarm_status !== 1){
              linksArray.push({
                link: this.$store.getters.GET_PATHS.getPdfFile + '/' + item.user_proform_document_package_file?.uuid + '?print=true' + `&orderNovaPoshta=${item.id}`,
                value: this.$t('common_DocsNPG.localization_value.value'),
                type: 'docs',
                target: '_blank',
                disabledBtn: !item.enabled_user_proform_document,
                click: () => {
                  return false
                }
              })
            }

            break

            /** UPS LINKS **/
          case UPS_DELIVERY_SERVICE.id:
            /**
             * Link Docs UPS
             */
            if(this.isCompletedStatus(item.status) &&
                item.user_proform_document_package_file && item.user_proform_document_package_file?.uuid && item.alarm_status !== 1 && (item.order_sender_type === 'by_himself' || this.isAdmin)){
              linksArray.push({
                link: this.$store.getters.GET_PATHS.getPdfFile + '/' + item.user_proform_document_package_file?.uuid + '?print=true' + `&orderNovaPoshta=${item.id}`,
                value: this.$t('common_DocsUPS.localization_value.value'),
                type: 'docs',
                target: '_blank',
                disabledBtn: !item.enabled_user_proform_document,
                click: () => {
                  return false
                }
              })
            }

            break
        }

        /**
         * Common Links Express
         */
        if(!this.isWaitForPayStatus(item.status) && (!this.waitingMakingLabelStatus(item.status) || this.isAdmin)) {

          /**
           * Link Edit
           */
          if ((!this.isCompletedStatus(item.status) && item?.payed_invoice?.status !== 'payed') || this.isAdmin) {
            linksArray.push({
              link: this.$store.getters.GET_PATHS.expressOrderEdit + '/' +
                  orderType.name + '/' + item.id,
              value: this.$t('common_edit.localization_value.value'),
              type: 'edit',
              click: () => {
                return false
              }
            })
          }

          /**
           * Link Delete
           */
          if (!this.isCompletedStatus(item.status) || this.isAdmin) {
            linksArray.push({
              link: '',
              value: this.$t('common_delete.localization_value.value'),
              type: 'delete',
              click: () => {
                this.$emit('removeExpress')
              }
            })
          }

          /**
           * Link Copy
           */

          if(!this.waitingMakingLabelStatus(item.status)) {
            linksArray.push({
              link: '',
              value: this.$t('common_copy.localization_value.value'),
              type: 'copy',
              click: () => {
                this.$emit('copyExpress')
              }
            })
          }

        }

        if ((!this.isCompletedStatus(item.status) && item?.payed_invoice?.status !== 'payed') || this.isAdmin) {

          /**
           * Link Create Label
           */
          if(
              (this.isCopyStatus(item.status) && this.isDHLNoTrn) || (!this.isCompletedStatus(item.status) &&
              ((this.hasTransaction(item) || this.isDHLNoTrn) || (this.isAdmin && !this.waitingMakingLabelStatus(item.status))))
          ){
            linksArray.push({
              link: '',
              value: this.$t('common_CreateLabel.localization_value.value'),
              type: 'label2',
              click: () => {
                this.$emit('showBuyLabelPopup')
              }
            })
          }

          /**
           * Link Confirm Label
           */
          if(this.isAdmin && this.waitingMakingLabelStatus(item.status)){
            linksArray.push({
              link: '',
              value: this.$t('common_ConfirmLabel.localization_value.value'),
              type: 'label2',
              click: () => {
                this.$emit('showBuyLabelPopup', true)
              }
            })
            // linksArray.push({
            //   link: '',
            //   value: this.$t('common_ConfirmLabel.localization_value.value'),
            //   type: 'dog',
            //   click: () => {
            //     this.$emit('confirmLabel')
            //   }
            // })
          }

          /**
           * Link Buy Label
           */
          if((!this.hasTransaction(item) && !this.isDHLNoTrn) &&
              !this.isCompletedStatus(item.status) &&
              !this.isAdmin){
            linksArray.push({
              link: '',
              value: this.$t('express_BuyLabel.localization_value.value'),
              type: 'dollar',
              click: () => {
                this.$emit('showBuyLabelPopup')
              }
            })
          }

          // /**
          //  * Link Proforma
          //  */
          // if(((this.isDHLNoTrn || (this.isFedexNoTrn && !this._.has(item.warehouse, 'id')) || this.isTNTNoTrn) && !this.isAdmin) || this.isAdmin){
          //   linksArray.push({
          //     link: '',
          //     value: this.$t('common_Proforma.localization_value.value'),
          //     type: 'proforma',
          //     click: () => {
          //       this.$emit('exportDocsExpress')
          //       return false
          //     }
          //   })
          // }


        }

        /**
         * Thermal Print
         */

        linksArray.push({
          link: '',
          value: this.$t('common_ThermalPrint.localization_value.value'),
          type: 'print',
          click: () => {
            this.$emit('thermalPrint', {id: [item.id]})
          }
        })

        if(this.waitingMakingLabelStatus(item.status)){
          /**
           * Link Copy
           */

          linksArray.push({
            link: '',
            value: this.$t('common_copy.localization_value.value'),
            type: 'copy',
            click: () => {
              this.$emit('copyExpress')
            }
          })
        }

        /**
         * Links for type express order
         */
        switch (orderType.id){

          /** DHL LINKS **/
          case DHL_DELIVERY_SERVICE.id:

            // /**
            //  * Link Docs DHL
            //  */
            // if(this.isCompletedStatus(item.status)){
            //   linksArray.push({
            //     link: this.$store.getters.GET_PATHS.getPdfFile + '/' + item.user_proform_document_package_file?.uuid + '?print=true' + `&orderDHL=${item.id}`,
            //     value: this.$t('common_DocsDHL.localization_value.value'),
            //     type: 'dog',
            //     target: '_blank',
            //     click: () => {
            //       return false
            //     }
            //   })
            // }

            /**
             * Link Proforma
             */
            if((this.isCompletedStatus(item.status) && (this.isDHLNoTrn || this.isAdmin))){
              linksArray.push({
                link: '',
                value: this.$t('common_Proforma.localization_value.value'),
                type: 'proforma',
                click: () => {
                  this.$emit('exportDocsExpress')
                  return false
                }
              })
            }

            /**
             * Link Call Pickup
             */
            if((this.isCompletedStatus(item.status) && this.canCallCourier(item)) && (item.order_sender_type === 'by_himself' || this.isAdmin)){
              linksArray.push({
                link: '',
                value: this.$t('common_CallPickup.localization_value.value'),
                type: 'pickup',
                click: () => {
                  this.$emit('showCallingCourierPopup')
                }
              })
            }

            /**
             * Link CourierDetails
             */
            if((this.isCompletedStatus(item.status) &&
                this.canCancelCourier(item) ) || (item.pickup_data) ){
              linksArray.push({
                link: '',
                value: this.$t('common_courierInfo.localization_value.value'),
                type: 'pickup',
                click: () => {
                  this.$emit('showCourierDetailPopup')
                }
              })
            }

            /**
             * Link Cancel Courier
             */
            if((this.isCompletedStatus(item.status) &&
                this.canCancelCourier(item) ) || (item.pickup_data) ){
              linksArray.push({
                link: '',
                value: this.$t('common_CancelCourier.localization_value.value'),
                type: 'pickup',
                click: () => {
                  this.$emit('expressCancelCourier')
                }
              })
            }
            break


          /** TNT LINKS **/
          case TNT_DELIVERY_SERVICE.id:
            // /**
            //  * Link Docs DHL
            //  */
            // if(this.isCompletedStatus(item.status) && (this.isTNTNoTrn || this.isAdmin)){
            //   linksArray.push({
            //     link: this.$store.getters.GET_PATHS.getPdfFile + '/' + item.user_proform_document_package_file?.uuid + '?print=true' + `&orderTNT=${item.id}`,
            //     value: this.$t('common_DocsTNT.localization_value.value'),
            //     type: 'dog',
            //     target: '_blank',
            //     click: () => {
            //       return false
            //     }
            //   })
            // }

            /**
             * Link Proforma
             */
            if((this.isCompletedStatus(item.status) && (this.isTNTNoTrn || this.isAdmin))){
              linksArray.push({
                link: '',
                value: this.$t('common_Proforma.localization_value.value'),
                type: 'proforma',
                click: () => {
                  this.$emit('exportDocsExpress')
                  return false
                }
              })
            }

            /**
             * Link Reference
             */
            if(this.isTNTService(item.delivery_service_id) && this.isCompletedStatus(item.status)){
              linksArray.push({
                link: '',
                value: this.$t('common_Reference.localization_value.value'),
                type: 'reference',
                click: () => {
                  this.$emit('showReferencePopup', 'ref')
                }
              })
            }

            /**
             * Link Reference
             */
            if(this.isTNTService(item.delivery_service_id) && this.isCompletedStatus(item.status)){
              linksArray.push({
                link: '',
                value: this.$t('express_BookingReference.localization_value.value'),
                type: 'reference',
                click: () => {
                  this.$emit('showReferencePopup', 'refBooking')
                }
              })
            }

            break

          /** FEDEX LINKS **/
          case FEDEX_DELIVERY_SERVICE.id:
            // /**
            //  * Link Docs FEDEX
            //  */
            // if(this.isCompletedStatus(item.status) && ((this.isFedexNoTrn && !this._.has(item.warehouse, 'id')) || this.isAdmin)){
            //   linksArray.push({
            //     link: this.$store.getters.GET_PATHS.getPdfFile + '/' + item.user_proform_document_package_file?.uuid + '?print=true' + `&orderFEDEX=${item.id}`,
            //     value: this.$t('common_DocsFedEx.localization_value.value'),
            //     type: 'dog',
            //     target: '_blank',
            //     click: () => {
            //       return false
            //     }
            //   })
            // }

            /**
             * Link Proforma
             */
            if(this.isCompletedStatus(item.status) && ((this.isFedexNoTrn && !this._.has(item.warehouse, 'id')) || this.isAdmin) ){
              linksArray.push({
                link: '',
                value: this.$t('common_Proforma.localization_value.value'),
                type: 'proforma',
                click: () => {
                  this.$emit('exportDocsExpress')
                  return false
                }
              })
            }

            /**
             * Link Call Pickup
             */
            if(
                !this._.has(item.warehouse, 'id') &&
              (
                // this.getUserFedexNoTransaction &&
                this.isCompletedStatus(item.status) &&
                // (!this.availableCourierTime) &&
                // (!this.isBelarus || this.isUserAlternativeContactPoland) &&
                this.canCallCourier(item)
              ) || this.isAdmin){
              linksArray.push({
                link: '',
                value: this.$t('common_CallPickup.localization_value.value'),
                type: 'pickup',
                click: () => {
                  this.$emit('showCallingCourierPopup')
                }
              })
            }

            /**
             * Link CourierDetails
             */
            if((this.isCompletedStatus(item.status) &&
                this.canCancelCourier(item) ) || (item.pickup_data) ){
              linksArray.push({
                link: '',
                value: this.$t('common_courierInfo.localization_value.value'),
                type: 'pickup',
                click: () => {
                  this.$emit('showCourierDetailPopup')
                }
              })
            }

            /**
             * Link Cancel Courier
             */
            if((this.isCompletedStatus(item.status) &&
              this.canCancelCourier(item) ) || (this.isAdmin && item.pickup_data) ){
              linksArray.push({
                link: '',
                value: this.$t('common_CancelCourier.localization_value.value'),
                type: 'pickup',
                click: () => {
                  this.$emit('expressCancelCourierPopup')
                }
              })
            }

            /**
             * Link Generate Certificate of origin
             */
            if(this.isAdmin && Object.hasOwnProperty.call(item, 'fedex_coof_document_file') && item.fedex_coof_document_file){
              linksArray.push({
                link: '',
                value: this.$t('common_GenerateCertificateOfOrigin.localization_value.value'),
                type: 'dog',
                click: () => {
                  this.$emit('exportConfirmationDocument', 'fedexCoofDocumentFile')
                }
              })
            }

            /**
             * Link Drop ball toxic substance
             */
            if(Object.hasOwnProperty.call(item, 'fedex_dbts_document_file') && item.fedex_dbts_document_file){
              linksArray.push({
                link: '',
                value: this.$t('common_DropBallToxicSubstance.localization_value.value'),
                type: 'dog',
                click: () => {
                  this.$emit('exportConfirmationDocument', 'fedexDbtsDocumentFile')
                }
              })
            }

            /**
             * Link Toxic Substance Control Act Certification
             */
            if(Object.hasOwnProperty.call(item, 'fedex_tsca_document_file') && item.fedex_tsca_document_file && (this.isAdmin || !this._.has(item.warehouse, 'id'))){
              linksArray.push({
                link: '',
                value: this.$t('common_ToxicSubstanceControlActCertification.localization_value.value'),
                type: 'dog',
                click: () => {
                  this.$emit('exportConfirmationDocument', 'fedexTscaDocumentFile')
                }
              })
            }

            break
        }

        if(orderType.id !== NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id) {
          linksArray = [...linksArray, ...this.getLinksNovaPoshta(item)]
        }

        // linksArray = [...linksArray, ...this.getLinksCDEK(item)]



        this.completeArray = true
        return linksArray
      },

      getLinksNovaPoshta(item){

        let linksArray = []

        /**
         * Links Nova Poshta
         */

        if((!this.isBelarus && !this.isSNGGroup && !this.isLatvian) || this.isAdmin){

          // if(!this.isWaitForPayStatus(item.status)) return linksArray

          if(!this._.has(this.currentPermissions, this.PERMISSIONS.ORDER_INTERNAL_NOVAPOSHTA)) return linksArray

          if(!this.isCompletedStatus(item.status)) return linksArray

          let isDHLUserSender = DHL_DELIVERY_SERVICE.id === this.getExpressTypeById(item.delivery_service_id)?.id && !item.warehouse?.id
          if(this.canCreateOrderInternal(item) && !isDHLUserSender){
            linksArray.push({
              link: this.$store.getters.GET_PATHS.novaPoshtaCreation + '/' + ORDER_TYPE_EXPRESS + '/' + item.id,
              value: this.$t('express_novaPoshta.localization_value.value'),
              type: 'pickup',
              click: () => {
                return false
              }
            })
          }

          /**
           * If creation internal not Nova Poshta
           */
          if(item?.order_internal && !this.isInternalNovaPoshta(item)) return linksArray


          if(item.order_internal && !this.isInternalLabelCreated(item.order_internal)){
            linksArray.push({
              link: this.$store.getters.GET_PATHS.novaPoshta + '/' + item.order_internal_id,
              value: this.$t('express_novaPoshtaEdit.localization_value.value'),
              type: 'pickup',
              click: () => {
                return false
              }
            })
          }

          if(this.isInternalLabelCreated(item.order_internal)){
            linksArray.push({
              link: this.$store.getters.GET_PATHS.novaPoshta + '/show/' + item.order_internal_id,
              value: this.$t('express_novaPoshtaShow.localization_value.value'),
              type: 'pickup',
              click: () => {
                return false
              }
            })
          }

          if(this.isInternalDraft(item.order_internal)){
            linksArray.push({
              value: this.$t('poshta_CreateNovaPoshtaLabel.localization_value.value'),
              type: 'dog',
              click: () => {
                this.$emit('createLabelNovaPoshta')
              }
            })
          }


          if(this.isInternalLabelCreated(item.order_internal)){
            linksArray.push({
              link: this.$store.getters.GET_PATHS.getPdfFile + '/' + this.getNovaPoshtaLabelUUID(item.order_internal.file_entries),
              value: this.$t('poshta_Label.localization_value.value'),
              type: 'mark',
              target: '_blank',
              click: () => {
                return false
              }
            })
          }

          if(this.isInternalLabelCreated(item.order_internal)){
            linksArray.push({
              link: this.$store.getters.GET_PATHS.getPdfFile + '/' + this.getNovaPoshtaMarkingUUID(item.order_internal.file_entries),
              value: this.$t('poshta_Marking.localization_value.value'),
              type: 'mark',
              target: '_blank',
              click: () => {
                return false
              }
            })
          }

        }

        return linksArray
      },

      // getLinksCDEK(item){
      //
      //   let linksArray = []
      //
      //
      //   /**
      //    * Links CDEK
      //    */
      //
      //   if(this.isBelarus || this.isSNGGroup ||  this.isAdmin){
      //
      //     // if(!this.isWaitForPayStatus(item.status)) return linksArray
      //
      //     if(!this._.has(this.currentPermissions, this.PERMISSIONS.ORDER_INTERNAL_CDEK)) return linksArray
      //
      //     /**
      //      * Link CDEK Create
      //      */
      //
      //     // if(this.canCreateOrderInternal(item)){
      //     //   linksArray.push({
      //     //     link: this.$store.getters.GET_PATHS.cdekCreation + '/' + ORDER_TYPE_EXPRESS + '/' + item.id,
      //     //     value: this.$t('express_CDEKCreate.localization_value.value'),
      //     //     type: 'dog',
      //     //     click: () => {
      //     //       return false
      //     //     }
      //     //   })
      //     // }
      //
      //     /**
      //      * If creation internal not CDEK
      //      */
      //     if(item?.order_internal && !this.isInternalCDEK(item)) return linksArray
      //
      //     /**
      //      * Link CDEK Edit
      //      */
      //     // if(item.order_internal && !this.isInternalLabelCreated(item.order_internal)){
      //     //   linksArray.push({
      //     //     link: this.$store.getters.GET_PATHS.cdek + '/' + item.order_internal_id,
      //     //     value: this.$t('express_CDEKEdit.localization_value.value'),
      //     //     type: 'edit',
      //     //     click: () => {
      //     //       return false
      //     //     }
      //     //   })
      //     // }
      //
      //     /**
      //      * Link CDEK Show
      //      */
      //     // if(item.order_internal && this.isInternalLabelCreated(item.order_internal)){
      //     //   linksArray.push({
      //     //     link: this.$store.getters.GET_PATHS.cdek +'/show/' + item.order_internal_id,
      //     //     value: this.$t('express_CDEKShow.localization_value.value'),
      //     //     type: 'docs',
      //     //     click: () => {
      //     //       return false
      //     //     }
      //     //   })
      //     // }
      //
      //     /**
      //      * Link CDEK Create Label
      //      */
      //
      //     if(this.isInternalDraft(item.order_internal)){
      //       linksArray.push({
      //         link: '',
      //         value: this.$t('express_CDEKCreateLabel.localization_value.value'),
      //         type: 'dog',
      //         click: () => {
      //           this.$emit('getDeliveryPriceCDEK')
      //         }
      //       })
      //     }
      //
      //     /**
      //      * Link CDEK Waybill (Product Label)
      //      */
      //
      //     if(this.isInternalLabelCreated(item.order_internal)){
      //       linksArray.push({
      //         link: this.$store.getters.GET_PATHS.getPdfFile + '/' + this.getCDEKWaybillUUID(item.order_internal.file_entries),
      //         value: this.$t('express_CDEKWaybill.localization_value.value'),
      //         type: 'dog',
      //         target: '_blank',
      //         click: () => {
      //           return false
      //         }
      //       })
      //     }
      //
      //     /**
      //      * Link CDEK Barcode (Order Label)
      //      */
      //
      //     if(this.isInternalLabelCreated(item.order_internal)){
      //       linksArray.push({
      //         link: this.$store.getters.GET_PATHS.getPdfFile + '/' + this.getBarcodeUUID(item.order_internal.file_entries),
      //         value: this.$t('express_CDEKBarcode.localization_value.value'),
      //         type: 'dog',
      //         target: '_blank',
      //         click: () => {
      //           return false
      //         }
      //       })
      //     }
      //   }
      //
      //   return linksArray
      //
      // },



    }

  }
</script>

<style scoped>
  .mt--10{
    margin-top: -10px;
  }
</style>
