<template>
  <modal
      @close="$emit('close')"
      class="custom-popup"
  >
    <template slot="header">
      <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['common_CustomLimits'])"></span>
        {{ $t('common_CustomLimits.localization_value.value') }}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="section-label">
          {{ $store.getters.getCustomLimitsItem.country.name }}
        </div>

        <p class="fsz14 custom-limits-txt"
           v-html="$store.getters.getCustomLimitsItem['customs_info_' + $store.getters.GET_LANG] ? $store.getters.getCustomLimitsItem['customs_info_' + $store.getters.GET_LANG] : $store.getters.getCustomLimitsItem['customs_info_en']"></p>
      </div>
    </template>
    <template slot="footer">
      <div class="d-flex align-items-center mt-4"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['common_ViewSource', 'common_ClearThanks'])"></span>

        <!--<span-->
            <!--class="site-link site-link&#45;&#45;alt buy-label__btn-i mr-3"-->
            <!--@click="$emit('close')"-->
        <!--&gt;-->
          <!--{{ $t('common_ViewSource.localization_value.value') }}-->
        <!--</span>-->

        <MainButton
            :value="$t('common_ClearThanks.localization_value.value')"
            class="buy-label__btn-i wfc "
            @click.native="$emit('close')"
        />
      </div>
    </template>
  </modal>

</template>

<script>
  import Modal from '../../../commonModals/Modal.vue'
  import MainButton from '../../../UI/buttons/MainButton/MainButton.vue'

  export default {
    name: "CustomLimitsPopup",
    components: {
      Modal,
      MainButton,
    },

  }

</script>

<style lang="scss">

  @import "../../../../scss/mixins/mixins";

  .wrong-orders-for-popup .modal-component__inner{
    max-width: 570px;
  }

  @include for-550{
    .wrong-orders-for-popup .buy-label__btn{
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start!important;
    }

    .wrong-orders-for-popup .buy-label__btn-i.mr-3{
      margin-bottom: 15px;
    }
  }

  .custom-limits-txt a{
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #CD9E64;
    text-decoration: none;
    transition: .3s;
    cursor: pointer;
  }

  .custom-limits-txt p {
    margin: 0;
  }

</style>
