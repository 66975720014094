<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row">
        <DefaultCheckbox class="mt-1"
                         :selectedNow="selectedNow"
                         :dataValue="dataValue"
        />
        <router-link :to="$store.getters.GET_PATHS.expressOrderShow + '/' +
												getExpressTypeById(item.delivery_service_id).name + '/' + item.id">
          <div class="table-card__item-number">
            #{{item.id}}
          </div>
        </router-link>
      </div>
      <div class="table-card__item-head-row">
        <div class="table-card__item-head-txt">
          {{ item.created_at | moment("DD MMM, YYYY") }}
        </div>
        <div class="table-card__item-head-txt">
          <v-popover
              class="site-tooltip"
              offset="5"
              placement="top"
              trigger="hover"
          >
            <div class="ellipsis">
              <ValueHelper
                  v-if="TNT_DELIVERY_SERVICE.id == item.delivery_service_id"
                  :value="item.tracking_number"
                  :fullValue="'GE'+item.tracking_number+'UA'"
              />
              <ValueHelper
                  v-else
                  style="padding-top: 1px"
                  :value="item.tracking_number"
              />
            </div>
            <template slot="popover">
              <p>
                <ValueHelper
                    v-if="TNT_DELIVERY_SERVICE.id == item.delivery_service_id"
                    :value="item.tracking_number"
                    :fullValue="'GE'+item.tracking_number+'UA'"
                />
                <ValueHelper
                    v-else
                    style="padding-top: 1px"
                    :value="item.tracking_number"
                />
              </p>
            </template>
          </v-popover>

          <!--<template v-if="TNT_DELIVERY_SERVICE.id == item.delivery_service_id">-->
            <!--GE</template>{{item.tracking_number}}<template v-if="TNT_DELIVERY_SERVICE.id == item.delivery_service_id">UA-->
        <!--</template>-->
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col" v-if="isAdmin">
            <div class="table-card__item-label">
              {{$t('common_user.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <TableUserColumn
                      :item="item"
                      :mobile="true"
              />
            </div>
          </div>

          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{$t('express_receiver.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.recipient_contact_name}}
            </div>
          </div>

          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('express_order.localization_value.value') }}
            </div>
            <div class="table-card__item-info align-items-center">
              <span class="table-ico pb-1">
                <img
                        v-if="TNT_DELIVERY_SERVICE.id == item.delivery_service_id"
                        src="/img/company-icons-group/tab-tnt.svg" alt="ico">
                <img
                        v-if="DHL_DELIVERY_SERVICE.id == item.delivery_service_id"
                        src="/img/company-icons-group/tab-dhl.svg" alt="ico">
                <img
                        v-if="FEDEX_DELIVERY_SERVICE.id == item.delivery_service_id"
                        src="/img/company-icons-group/tab-fedex.svg" alt="ico">
                <img
                    v-if="NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id == item.delivery_service_id"
                    src="/img/company-icons-group/logo-poshta-global-table.svg" alt="ico">
                <img
                    v-if="UPS_DELIVERY_SERVICE.id == item.delivery_service_id"
                    src="/img/company-icons-group/ups.svg" alt="ico">
              </span>
              #{{item.id}}
              <span class="table-ico ml-2">
                  <LabelCircle v-if="item.payment_transaction_id"
                               :color="'green'" :value="'T'" />
                  <LabelCircle v-else
                               :color="'gray-light'" :value="'N'" />
                </span>
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('express_Created.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('express_Country.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{item.recipient_country.name}}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('ex_sendFrom.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <template v-if="!Array.isArray(item.warehouse)">
                <ValueHelper
                    :value="item.warehouse"
                    :deep="'sender_city'"
                />
              </template>
              <template v-else>{{$t('ex_sender.localization_value.value')}}</template>
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('express_Ship.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  v-if="item.payed_invoice"
                  :value="item.payed_invoice.total_cost_amount ? item.payed_invoice.total_cost_amount : item.payed_invoice.total_amount"
              />
              <template v-else>—</template>
            </div>
          </div>
        </div>

        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label d-flex align-items-center">
              <template v-if="(!isBelarus && !isSNGGroup && !isLatvian) && !isAdmin">
                <img src="/img/company-icons-group/novaposhta-logo-thead.png" alt="ico" width="20" height="19">
              </template>
              <template v-if="(isBelarus || isSNGGroup) && !isAdmin">
                <img src="/img/company-icons-group/cdek.png" alt="ico" width="20" height="19">
              </template>
              {{$t('express_TrackingNumberCDEKNP.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <template v-if="item.new_post_tracking_number !== null && item.new_post_tracking_number !== ''">
                <!--<span>{{item.tracking_number}}</span>-->
                <div class="d-flex align-items-center">
                  <v-popover
                      class="site-tooltip"
                      offset="5"
                      placement="top"
                      trigger="hover"
                  >
                    <div class="table-link btn-style ellipsis">
                      {{item.new_post_tracking_number}}
                    </div>
                    <template slot="popover">
                      <p>{{ item.new_post_tracking_number }}</p>
                    </template>
                  </v-popover>
                  <div class="table-ico">
                    <!--v-if="item.tracking_sent === 0"-->
                    <v-popover
                        class="site-tooltip"
                        offset="5"
                        placement="top-left"
                        @show="hideEditField = true"
                        @hide="hideEditField = false"
                    >
                      <span class="site-link">
                          <LinkButton
                              :type="'edit'"
                          />
                      </span>
                      <template slot="popover">
                        <div class="section-label mb-2">{{ $t('importO_trackingNumber.localization_value.value') }}
                        </div>
                        <InputEditField
                            :value="item.new_post_tracking_number"
                            :propId="item.id"
                            :hideEditField="hideEditField"
                            @onChange="data => {$emit('changeNovaPoshtaTrackingNumberExpress', {value: data.value, id: data.id})}"
                        />
                        <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                      </template>
                    </v-popover>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="">
                  <v-popover
                          class="site-tooltip"
                          offset="5"
                          placement="top-left"
                          @show="hideEditField = true"
                          @hide="hideEditField = false"
                  >
                    <span class="table-link btn-style">{{ $t('importO_addNumber.localization_value.value') }}</span>

                    <template slot="popover">
                      <div class="section-label mb-2">{{ $t('importO_trackingNumber.localization_value.value') }}
                      </div>
                      <InputEditField
                              :value="''"
                              :propId="item.id"
                              :hideEditField="hideEditField"
                              @onChange="data => {$emit('changeNovaPoshtaTrackingNumberExpress', {value: data.value, id: data.id})}"
                      />

                      <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                    </template>
                  </v-popover>
                </div>
              </template>
            </div>
          </div>
        </div>

        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{$t('express_TrackingNumber.localization_value.value')}}
            </div>
            <div class="table-card__item-info"

            >

<!--              <div @click="$emit('showStatusOrderDetailsPopup')">-->
<!--                <template v-if="item.status == EXPRESS_DELIVERY_STATUSES.COMPLETED_STATUS">-->
<!--                  <StatusIcoBtn class="mr-1" :type="'delivered'"-->
<!--                                @click.native="$emit('showStatusOrderDetailsPopup')"/>-->
<!--                </template>-->
<!--                <template v-if="item.status == EXPRESS_DELIVERY_STATUSES.IN_PROGRESS_STATUS">-->
<!--                  <StatusIcoBtn class="mr-1" :type="'in-transit'"-->
<!--                                @click.native="$emit('showStatusOrderDetailsPopup')"/>-->
<!--                </template>-->
<!--              </div>-->
              <TableTrackingLog
                  v-if="item.delivery_state.delivery_status"
                  :trackingLogItem="null"
                  :trackingStateEvent="item.delivery_state"
              />


              <template v-if="$store.getters.getIsAdminRights === 'user'">
                <v-popover
                    class="site-tooltip"
                    offset="5"
                    placement="top"
                    trigger="hover"
                >
                  <div class="ellipsis">
                    {{item.tracking_number}}
                  </div>
                  <template slot="popover">
                    <p>{{item.tracking_number}}</p>
                  </template>
                </v-popover>
              </template>

              <div class="table-row"
                   v-else
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit"
                      @click="editTranslate(['importO_trackingNumber', 'importO_addNumber'])"></span>
                <template v-if="item.tracking_number !== null && item.tracking_number !== ''">
                  <div class="table-link btn-style d-flex align-items-center">
                    <v-popover
                        class="site-tooltip"
                        offset="5"
                        placement="top"
                        trigger="hover"
                    >
                      <div class="table-link btn-style ellipsis">
                        {{item.tracking_number}}
                      </div>
                      <template slot="popover">
                        <p>{{item.tracking_number}}</p>
                      </template>
                    </v-popover>
                  </div>
                  <div class="table-ico">
                    <!--v-if="item.tracking_sent === 0"-->
                    <v-popover
                            class="site-tooltip"
                            offset="5"
                            placement="top-left"
                            @show="hideEditField = true"
                            @hide="hideEditField = false"
                    >
                    <span class="site-link">
                        <LinkButton
                                :type="'edit'"
                        />
                    </span>
                      <template slot="popover">
                        <div class="section-label mb-2">{{ $t('importO_trackingNumber.localization_value.value') }}
                        </div>
                        <InputEditField
                                :value="item.tracking_number"
                                :propId="item.id"
                                :hideEditField="hideEditField"
                                @onChange="$emit('changeTrackingNumberExpress')"
                        />
                        <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                      </template>
                    </v-popover>
                  </div>
                </template>
                <template v-else>
                  <div class="">
                    <v-popover
                            class="site-tooltip"
                            offset="5"
                            placement="top-left"
                            @show="hideEditField = true"
                            @hide="hideEditField = false"
                    >
                      <span class="table-link btn-style">{{ $t('importO_addNumber.localization_value.value') }}</span>

                      <template slot="popover">
                        <div class="section-label mb-2">{{ $t('importO_trackingNumber.localization_value.value') }}
                        </div>
                        <InputEditField
                                :value="''"
                                :propId="item.id"
                                :hideEditField="hideEditField"
                                @onChange="$emit('changeTrackingNumberExpress')"
                        />

                        <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                      </template>
                    </v-popover>
                  </div>
                </template>



                <template v-if="FEDEX_DELIVERY_SERVICE.id === item.delivery_service_id &&
                item.document_confirmation_payload &&
                item.document_confirmation_payload.length > 0"
                >
                  <div class="d-flex">
                    <v-popover class="site-tooltip d-flex" offset="5" placement="top-left" trigger="hover"
                               v-for="(itemDocument, index) in item.document_confirmation_payload"
                               :key="index"
                    >
                    <span>
                      <LabelCircle
                              :color="itemDocument['Status'] === 'SUCCESS' ? 'green' : 'gray-light'"
                              :value="getCustomerReferenceType(itemDocument['CustomerReference']).type"
                              class="mr-8 mb-2"
                      />
                    </span>
                      <template slot="popover">
                        <div class="status-tooltip">
                          <LabelCircle
                                  :color="itemDocument['Status'] === 'SUCCESS' ? 'green' : 'gray-light'"
                                  :value="getCustomerReferenceType(itemDocument['CustomerReference']).type"
                                  class="mr-8"/>
                          {{itemDocument['Status'] === 'SUCCESS' ? 'Success' : 'Error'}}
                          {{getCustomerReferenceType(itemDocument['CustomerReference']).value}}
                        </div>
                      </template>
                    </v-popover>
                  </div>
                </template>
              </div>

              <!--<v-popover-->
                      <!--class="site-tooltip"-->
                      <!--offset="5"-->
                      <!--placement="top-left"-->
                      <!--trigger="hover"-->
              <!--&gt;-->
													<!--<span>-->
														<!--<StatusIcoBtn-->
                                    <!--:type="'active'"-->
                                    <!--class="mr-1"-->
                                    <!--@click.native="$emit('showStatusOrderDetailsPopup')"-->
                            <!--/>-->
													<!--</span>-->
                <!--<template slot="popover">-->
                  <!--<div class="status-tooltip">-->
                    <!--<StatusIcoBtn :type="'active'" class="mr-2"/>-->
                    <!--<div v-if="item.status == EXPRESS_DELIVERY_STATUSES.COMPLETED_STATUS"><StatusIcoBtn class="mr-2" :type="'delivered'"/>{{$t('status_Returned.localization_value.value')}}</div>-->
                    <!--<div v-if="item.status == CUSTOMERS_PAYMENTS_STATUSES.IN_PROGRESS_STATUS"><StatusIcoBtn class="mr-2" :type="'in-transit'"/>{{$t('status_InProcess.localization_value.value')}}</div>-->
                  <!--</div>-->
                <!--</template>-->
              <!--</v-popover>-->
            </div>
          </div>
        </div>

        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{$t('express_DeliveryDate.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                      :value="item.delivery_date"
                      :date="true"
                      :dateType="'DD MMM, YYYY'"
              />
            </div>
          </div>
        </div>

        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{$t('express_ItemName.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <p class="mt-0 mb-2 mr-2">
                {{item.item_name}}
              </p>
            </div>
          </div>
        </div>

        <!--<div class="table-card__item-btn"-->
             <!--v-if="!waitingMakingLabelStatus(item.status) && !isWaitForPayStatus(item.status)">-->
          <!--<div class="table-card__item-btn-i"-->
               <!--v-if="!isCompletedStatus(item.status) ||-->
                              <!--$store.getters.getIsAdminRights === 'admin'"-->
          <!--&gt;-->
            <!--<LinkButton-->
                    <!--:link="$store.getters.GET_PATHS.expressOrderEdit + '/' +-->
													<!--checkExpressType(item.delivery_service_id) + '/' + item.id"-->
                    <!--:value="$t('common_edit.localization_value.value')"-->
                    <!--:type="'edit'"-->
            <!--/>-->
          <!--</div>-->
          <!--<div class="table-card__item-btn-i"-->
               <!--v-if="!isCompletedStatus(item.status)"-->
          <!--&gt;-->
            <!--<LinkButton-->
                    <!--:value="$t('common_delete.localization_value.value')"-->
                    <!--:type="'delete'"-->
                    <!--@click.native="$emit('removeExpress')"-->
            <!--/>-->
          <!--</div>-->
          <!--<div class="table-card__item-btn-i">-->
            <!--<LinkButton-->
                    <!--:value="$t('common_copy.localization_value.value')"-->
                    <!--:type="'copy'"-->
                    <!--@click.native="$emit('copyExpress')"-->
            <!--/>-->
          <!--</div>-->
          <!--<div class="table-card__item-btn-i"-->
               <!--v-if="!isDHLService(item.delivery_service_id)"-->
          <!--&gt;-->
            <!--<LinkButton-->
                    <!--:value="$t('common_Reference.localization_value.value')"-->
                    <!--:type="'reference'"-->
                    <!--@click.native="$emit('showReferencePopup')"-->
            <!--/>-->
          <!--</div>-->
          <!--<div class="table-card__item-btn-i"-->
               <!--v-if="isDHLNoTrn && !isCompletedStatus(item.status)"-->
          <!--&gt;-->
            <!--<LinkButton-->
                    <!--:value="$t('common_CreateLabel.localization_value.value')"-->
                    <!--:type="'dog'"-->
                    <!--@click.native="$emit('showBuyLabelPopup')"-->
            <!--/>-->
          <!--</div>-->
          <!--<div class="table-card__item-btn-i"-->
               <!--v-if="$store.getters.getIsAdminRights === 'admin' &&-->
                      <!--waitingMakingLabelStatus(item.status)"-->
          <!--&gt;-->
            <!--<LinkButton-->
                    <!--:value="$t('common_ConfirmLabel.localization_value.value')"-->
                    <!--:type="'dog'"-->
                    <!--@click.native="$emit('confirmLabel')"-->
            <!--/>-->
          <!--</div>-->
          <!--<div class="table-card__item-btn-i"-->
               <!--v-if="!isDHLNoTrn && !isCompletedStatus(item.status)"-->
          <!--&gt;-->
            <!--<LinkButton-->
                    <!--:value="$t('buyLabel.localization_value.value')"-->
                    <!--:type="'dog'"-->
                    <!--@click.native="$emit('showBuyLabelPopup')"-->
            <!--/>-->
          <!--</div>-->
          <!--<div class="table-card__item-btn-i"-->
               <!--v-if="isCompletedStatus(item.status) && isDHLService(item.delivery_service_id)"-->
          <!--&gt;-->
            <!--<LinkButton-->
                    <!--:value="$t('common_DocsDHL.localization_value.value')"-->
                    <!--:type="'docs'"-->
                    <!--@click.native="$emit('exportCommercialInvoice')"-->
            <!--/>-->
          <!--</div>-->
          <!--<div class="table-card__item-btn-i"-->
               <!--v-if="isCompletedStatus(item.status)"-->
          <!--&gt;-->
            <!--<LinkButton-->
                    <!--:value="$t('common_Proforma.localization_value.value')"-->
                    <!--:type="'proforma'"-->
                    <!--@click.native="$emit('exportDocsExpress')"-->
            <!--/>-->
          <!--</div>-->
          <!--<div class="table-card__item-btn-i"-->
               <!--v-if="isCompletedStatus(item.status) && !item.pickup_data"-->
          <!--&gt;-->
            <!--<LinkButton-->
                    <!--:value="$t('common_CallPickup.localization_value.value')"-->
                    <!--:type="'pickup'"-->
                    <!--@click.native="$emit('showCallingCourierPopup')"-->
            <!--/>-->
          <!--</div>-->
          <!--<div class="table-card__item-btn-i"-->
               <!--v-if="isCompletedStatus(item.status)"-->
          <!--&gt;-->
            <!--<LinkButton-->
                    <!--v-if="isDHLService(item.delivery_service_id) && item.pickup_data"-->
                    <!--:value="$t('common_CancelCourier.localization_value.value')"-->
                    <!--:type="'pickup'"-->
                    <!--@click.native="$emit('showCallingCourierPopup')"-->
            <!--/>-->
          <!--</div>-->
        <!--</div>-->

        <!--<div class="table-card__item-btn" v-if="!waitingMakingLabelStatus(item.status) && !isWaitForPayStatus(item.status)">-->
          <!--<div class="table-card__item-btn-i">-->
            <!--<LinkButton-->
                    <!--:value="$t('common_copy.localization_value.value')"-->
                    <!--:type="'copy'"-->
                    <!--@click.native="$emit('copyExpress')"-->
            <!--/>-->
          <!--</div>-->
        <!--</div>-->

        <div class="table-card__item-btn">
          <ExpressManageButton
                  :item="item"
                  @removeExpress="$emit('removeExpress')"
                  @copyExpress="$emit('copyExpress')"
                  @showReferencePopup="$emit('showReferencePopup')"
                  @showBuyLabelPopup="(confirm) => $emit('showBuyLabelPopup', confirm)"
                  @confirmLabel="$emit('confirmLabel')"
                  @exportCommercialInvoice="$emit('exportCommercialInvoice')"
                  @exportDocsExpress="$emit('exportDocsExpress')"
                  @showCallingCourierPopup="$emit('showCallingCourierPopup')"
                  @expressCancelCourier="$emit('expressCancelCourier')"
                  @expressCancelCourierPopup="$emit('expressCancelCourierPopup')"
                  @showCourierDetailPopup="$emit('showCourierDetailPopup')"
                  @createLabelNovaPoshta="$emit('createLabelNovaPoshta')"
                  @getDeliveryPriceCDEK="$emit('getDeliveryPriceCDEK')"
                  @barcodeCDEK="$emit('barcodeCDEK')"
                  @waybillCDEK="$emit('waybillCDEK')"
                  @thermalPrint="(data) => $emit('thermalPrint', data)"
          />
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import InputEditField from "../../../../../../../UI/inputs/InputEditField/InputEditField";
  import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  // import StatusIcoBtn from "../../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import {
    CUSTOMERS_PAYMENTS_STATUSES,
    DHL_DELIVERY_SERVICE, EXPRESS_DELIVERY_STATUSES,
    FEDEX_DELIVERY_SERVICE, NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE,
    TNT_DELIVERY_SERVICE, UPS_DELIVERY_SERVICE
  } from "../../../../../../../../staticData/staticVariables";
  import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";
  import {expressMixins} from "../../../../../../../../mixins/expressMixins/expressMixin";
  import ExpressManageButton from "../components/ExpressManageButton";
  import TableUserColumn from "../../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import LabelCircle from "../../../../../../../coreComponents/LabelCircle/LabelCircle";
  import TableTrackingLog from "@/components/coreComponents/TableComponents/TableTrackingLog/TableTrackingLog";
  // import EditBlack from '../../../../../../../../../public/img/common/edit-black.svg?inline'

  export default {
    name: "ExpressOrderTableUserTableMobile",

    components: {
      TableTrackingLog,
      LabelCircle,
      TableUserColumn,
      ExpressManageButton,
      ValueHelper,
      LinkButton,
      // StatusIcoBtn,
      DefaultCheckbox,
      InputEditField
    },

    mixins: [expressMixins],

    props: {
      item: Object,
      selectedNow: Boolean,
      dataValue: Number,
    },

    data() {
      return{
        show: false,
        hideEditField: false,

        DHL_DELIVERY_SERVICE: DHL_DELIVERY_SERVICE,
        FEDEX_DELIVERY_SERVICE: FEDEX_DELIVERY_SERVICE,
        TNT_DELIVERY_SERVICE: TNT_DELIVERY_SERVICE,

        EXPRESS_DELIVERY_STATUSES: EXPRESS_DELIVERY_STATUSES,
        CUSTOMERS_PAYMENTS_STATUSES: CUSTOMERS_PAYMENTS_STATUSES,
        NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE: NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE,
        UPS_DELIVERY_SERVICE: UPS_DELIVERY_SERVICE,
      }
    },

    watch: {
      item(newVal) {
        this.item = newVal
      },
    },

    methods: {
      onChange({value, id}) {
        this.$emit('changeNovaPoshtaTrackingNumberExpress', {value: value, id: id, ref: this.$refs.closePopover})
      },

      checkExpressType(type) {
        if(DHL_DELIVERY_SERVICE.id === type) return DHL_DELIVERY_SERVICE.name
        if(FEDEX_DELIVERY_SERVICE.id === type) return FEDEX_DELIVERY_SERVICE.name
        if(TNT_DELIVERY_SERVICE.id === type) return TNT_DELIVERY_SERVICE.name
      },
    }

  }
</script>

<style scoped>
.table-card__item-content .ellipsis {
  max-width: 120px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
}
</style>
