<template>
  <div class="detail-page">
    <div class="detail-page__left"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'express_orderId',
          'status_Returned',
          'status_InProcess',
          'express_PayPalTransaction',
        ])"></div>
      <CardLeftBlock
              :name="$t('express_orderId.localization_value.value')"
              :value="'#' + ex.express.expressObj.expressOrderInstance.id"
              v-if="item = ex.express.expressObj.expressOrderInstance"
      >
        <template slot="body">
          <!--<div class="d-flex align-items-center"-->
          <!--v-if="item.status == EXPRESS_DELIVERY_STATUSES.COMPLETED_STATUS">-->
          <!--<span class="site-status green"-->
          <!--@click="showStatusOrderDetailsPopup"-->
          <!--&gt;-->
          <!--<StatusIcoBtn :type="'delivered'" class="mr-1"/>-->
          <!--<span>{{$t('status_Returned.localization_value.value')}}</span>-->
          <!--</span>-->
          <!--</div>-->
          <!--<div class="d-flex align-items-center"-->
          <!--v-if="item.status == EXPRESS_DELIVERY_STATUSES.IN_PROGRESS_STATUS">-->
          <!--<span class="site-status"-->
          <!--@click="showStatusOrderDetailsPopup"-->
          <!--&gt;-->
          <!--<StatusIcoBtn :type="'in-transit'" class="mr-1"/>-->
          <!--<span>{{$t('status_InProcess.localization_value.value')}}</span>-->
          <!--</span>-->
          <!--</div>-->

          <div class="card-detail-left__date">
            {{ item.created_at | moment("DD MMM, YYYY") }}
          </div>

          <router-link v-if="ex.express.proformDocument && isAdmin"
                       :to="$store.getters.GET_PATHS.getPdfFile + '/' + ex.express.proformDocument.uuid +
                          '?print=true' + `&${getProformDocType}=${ex.express.expressId}`"
                       :target="'_blank'"
                       v-bind:class="{'disabled-btn' : !ex.express.expressObj.enabled_user_proform_document}"
          >
            <MainButton
                v-if="ex.express.type === DHL_DELIVERY_SERVICE.id"
                :ico="true"
                :value="$t('common_DocsDHL.localization_value.value')"
                class="card-detail-left__btn btn-fit-content mt-0"
            />
            <MainButton
                v-if="ex.express.type === FEDEX_DELIVERY_SERVICE.id"
                :ico="true"
                :value="$t('common_DocsFedEx.localization_value.value')"
                class="card-detail-left__btn btn-fit-content mt-0"
            />
            <MainButton
                v-if="ex.express.type === NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id"
                :ico="true"
                :value="$t('common_DocsNPG.localization_value.value')"
                class="card-detail-left__btn btn-fit-content mt-0"
            />
            <MainButton
                v-if="ex.express.type === UPS_DELIVERY_SERVICE.id"
                :ico="true"
                :value="$t('common_DocsUPS.localization_value.value')"
                class="card-detail-left__btn btn-fit-content mt-0"
            />
          </router-link>

          <div class="site-link brown text-decoration-underline mt-4">
            <div v-if="isAdmin && ex.express.historyOfChanged"
                 class="mb-3"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_CheckHistory'])"></div>
              <div class="site-link text-decoration-underline btn-style brown"
                   @click="historyOfChangesFunc('express', ex.express.historyOfChanged)">
                {{$t('common_CheckHistory.localization_value.value')}}
              </div>
            </div>
          </div>

        </template>
        <template slot="button">
          <router-link :to="$store.getters.GET_PATHS.cusPayments + '?order=10382'"
                       v-if="ex.express.typeTransaction === 'payment'"
          >
            <MainButton
                    :ico="true"
                    :value="$t('express_PayPalTransaction.localization_value.value')"
                    class="card-detail-left__btn"
            >
              <template slot="ico">
                <PayPalIcon/>
              </template>
            </MainButton>
          </router-link>
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head mb-3">

            <div class="order-create__head-line mb-0">
              <div class="order-create__header-col">
                <router-link v-if="isEasyType" :to="$store.getters.GET_PATHS.easyOrders" class="order-create__head-back">
                  <LinkBack
                      :value="$t('common_backTo.localization_value.value')"
                  />
                </router-link>
                <router-link v-else :to="$store.getters.GET_PATHS.expressOrders"
                             class="order-create__head-back">
                  <LinkBack
                          :value="$t('ex_backTo.localization_value.value')"
                  />
                </router-link>
              </div>
<!--              <div class="order-create__header-col justify-content-end d-flex">-->
<!--                <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton" class="alone"-->
<!--                               :position="'bottom'"-->
<!--                >-->
<!--                  <template slot="item">-->
<!--                    <LinkButton-->
<!--                            :value="$t('common_CreateLabel.localization_value.value')"-->
<!--                            :type="'dog'"-->
<!--                    />-->
<!--                  </template>-->
<!--                  <template slot="item">-->
<!--                    <LinkButton-->
<!--                            :value="$t('common_DocsFedEx.localization_value.value')"-->
<!--                            :type="'docs'"-->
<!--                    />-->
<!--                  </template>-->
<!--                  <template slot="item">-->
<!--                    <LinkButton-->
<!--                            :value="$t('common_Proforma.localization_value.value')"-->
<!--                            :type="'proforma'"-->
<!--                    />-->
<!--                  </template>-->
<!--                  <template slot="item">-->
<!--                    <LinkButton-->
<!--                            :value="$t('common_CallPickup.localization_value.value')"-->
<!--                            :type="'pickup'"-->
<!--                    />-->
<!--                  </template>-->
<!--                </ManagerButton>-->
<!--              </div>-->
            </div>
            <div class="order-create__head-title color-orange"
                 v-if="ex.express.type === TNT_DELIVERY_SERVICE.id"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['express_ShowExpressOrderTNT'])"></div>
              {{ $t('express_ShowExpressOrderTNT.localization_value.value') }}
            </div>
            <div class="order-create__head-title color-red"
                 v-if="ex.express.type === DHL_DELIVERY_SERVICE.id"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['express_ShowExpressOrderDHL'])"></div>
              {{ $t('express_ShowExpressOrderDHL.localization_value.value') }}
            </div>
            <div class="order-create__head-title color-blue"
                 v-if="ex.express.type === FEDEX_DELIVERY_SERVICE.id"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['express_ShowExpressOrderFedEx'])"></div>
              <template v-if="isEasyType">{{$t('easy_express.localization_value.value')}}</template>
              <template v-else>{{$t('express_NewExpressOrder.localization_value.value')}} (FedEx)</template>
            </div>
            <div class="order-create__head-title color-blue"
                 v-if="ex.express.type === NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              Nova poshta
            </div>
            <div class="order-create__head-title color-blue"
                 v-if="ex.express.type === UPS_DELIVERY_SERVICE.id"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              UPS
            </div>
          </div>
        </template>

        <template slot="body">
          <div class="order-create__section">

            <div class="order-create__title-horizontal"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
          'common_pickupInfo',
        ])"></div>
              <TitleHorizontal
                      :value="$t('common_pickupInfo.localization_value.value')"
                      @clickEdit="editTest"
              />

            </div>

            <div class="order-create__row custom-row"
                 v-if="Object.keys($store.getters.GET_COMMON_AUTHORIZED).length > 0">
              <div class="order-create__col custom-col"
                   v-if="AUTHORIZED = $store.getters.GET_COMMON_AUTHORIZED"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                    'common_address',
                    'common_city',
                    'common_region',
                    'common_country',
                    'common_zipCode',
                  ])"></div>
                <div class="order-create__table block-table">
                  <div class="block-table__name">
                    {{ $t('ex_sender.localization_value.value') }}
                  </div>
                  <div class="block-table__row">
                    <div class="block-table__col block-table__col--20">
                      <div class="block-table__label">
                        {{ $t('common_address.localization_value.value') }}
                      </div>
                      <div class="block-table__content">
                        <template v-if="ex.express.sendType === ex.express.sendTypes.warehouse &&
									ex.express.warehouse && ex.express.warehouse !== ''">
                          {{ex.express.warehouse.sender_address}}
                        </template>
                        <template v-else-if="Object.keys(ex.express.user).length > 0">
                          {{ getUserContactsBySelectedUser.address }}
                        </template>
                        <template v-else>
                          {{ AUTHORIZED.address }}
                        </template>
                      </div>
                    </div>
<!--                    <div class="block-table__col block-table__col&#45;&#45;20"-->
<!--                         v-if="ex.express.delivery.delivery.address2 !== '' && ex.express.sendType === ex.express.sendTypes.sender"-->
<!--                    >-->
<!--                      <div class="block-table__label">-->
<!--                        {{ $t('common_addressSecond.localization_value.value') }}-->
<!--                      </div>-->
<!--                      <div class="block-table__content">-->
<!--                        {{ ex.express.delivery.delivery.address2 }}-->
<!--                      </div>-->
<!--                    </div>-->
                    <div class="block-table__col block-table__col--20">
                      <div class="block-table__label">
                        {{ $t('common_city.localization_value.value') }}
                      </div>
                      <div class="block-table__content">
                        <template v-if="ex.express.sendType === ex.express.sendTypes.warehouse &&
									ex.express.warehouse && ex.express.warehouse !== ''">
                          {{ex.express.warehouse.sender_city}}
                        </template>
                        <template v-else-if="Object.keys(ex.express.user).length > 0">
                          {{ getUserContactsBySelectedUser.city }}
                        </template>
                        <template v-else>
                          {{ AUTHORIZED.city }}
                        </template>
                      </div>
                    </div>
<!--                    <div class="block-table__col block-table__col&#45;&#45;20">-->
<!--                      <div class="block-table__label">-->
<!--                        {{ $t('common_region.localization_value.value') }}-->
<!--                      </div>-->
<!--                      <div class="block-table__content">-->
<!--                        <template v-if="ex.express.warehouse">-->
<!--                          - -->
<!--                        </template>-->
<!--                        <template v-else-if="Object.keys(ex.express.user).length > 0">-->
<!--                          {{ ex.express.user.contacts[0].region.name }}-->
<!--                        </template>-->
<!--                        <template v-else>-->
<!--                          {{ AUTHORIZED.user.user_personal_contact.region.name }}-->
<!--                        </template>-->
<!--                      </div>-->
<!--                    </div>-->
                    <div class="block-table__col block-table__col--20">
                      <div class="block-table__label">
                        {{ $t('common_country.localization_value.value') }}
                      </div>
                      <div class="block-table__content">
                        <template v-if="ex.express.sendType === ex.express.sendTypes.warehouse &&
									ex.express.warehouse && ex.express.warehouse !== ''">
                          {{ ex.express.warehouse.country.name }}
                        </template>
                        <template v-else-if="Object.keys(ex.express.user).length > 0">
                          {{ getUserContactsBySelectedUser.country.name }}
                        </template>
                        <template v-else>
                          {{ AUTHORIZED.country.name }}
                        </template>
                      </div>
                    </div>
<!--                         v-bind:class="{'mt-3': ex.express.delivery.delivery.address2 !== ''}"-->
                    <div class="block-table__col block-table__col--20">
                      <div class="block-table__label">
                        {{ $t('common_zipCode.localization_value.value') }}
                      </div>
                      <div class="block-table__content">
                        <template v-if="ex.express.sendType === ex.express.sendTypes.warehouse &&
									ex.express.warehouse && ex.express.warehouse !== ''">
                          {{ex.express.warehouse.sender_postcode}}
                        </template>
                        <template v-else-if="Object.keys(ex.express.user).length > 0">
                          {{ getUserContactsBySelectedUser.zip }}
                        </template>
                        <template v-else>
                          {{ AUTHORIZED.zip }}
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="order-create__row custom-row"
                 v-if="ex.express.type === NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id">
              <div class="order-create__col custom-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="order-create__table block-table">
                  <div class="block-table__name">
                    {{$t('poshta__ServiceType.localization_value.value')}}
                  </div>
                  <div class="block-table__row">
                    <div class="block-table__col block-table__col--20" v-if="ex.express.serviceType === ex.express.serviceTypes.doors">
                      <div class="block-table__label">
                        {{$t('poshta__PersonalAddress.localization_value.value')}}
                      </div>
                    </div>
                    <div class="block-table__col block-table__col--66" v-else>
                      <div class="block-table__label">
                        {{$t('poshta__NovaPoshtaAddress.localization_value.value')}}
                      </div>
                      <div class="block-table__content">
                        {{ex.express.novaPoshtaAddress ? ex.express.novaPoshtaAddress.warehouse_name : '—'}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="order-create__title-horizontal"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
          'ex_products',
        ])"></div>
              <TitleHorizontal
                      :value="$t('ex_products.localization_value.value')"
              />
            </div>

            <div class="order-create__section-label section-label"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
          'ex_products',
        ])"></div>
              {{ $t('ex_products.localization_value.value') }}
            </div>

            <div class="custom-row">
              <div class="custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
                   v-for="(item, index) in ex.express.products"
                   :key="index"
              >
                <div class="confirm-product-card">
                  <div class="confirm-product-card__items">
                    <div class="confirm-product-card__item" v-if="item.product.proformData.proformCategory !== ''">
                      <div class="confirm-product-card__item-name"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate([
                          'proform_category',
                        ])"></div>
                        {{ $t('proform_category.localization_value.value') }}
                      </div>
                      <div class="confirm-product-card__item-val">
                        {{ item.product.proformData.proformCategory.translationStorage.en.name }}
                      </div>
                    </div>

                    <template v-if="localProformTypes = item.product.proformData.proformTypes">
                      <template v-for="(itemType, index) in $store.getters.getProformProductInitialize.proformTypes">
                        <div class="confirm-product-card__item" :key="index">
                          <div class="confirm-product-card__item-name"
                               v-if="_.has(localProformTypes[itemType.id], 'typeName')"
                          >
                            {{getProformTypeLabel(localProformTypes, itemType)}}
                          </div>
                          <div class="confirm-product-card__item-val"
                               v-if="_.has(localProformTypes[itemType.id], 'typeValue')"
                          >

                            {{
                            localProformTypes[itemType.id].typeValue !== '' ?
                            localProformTypes[itemType.id].typeValue.value.translationStorage[proformUserConfig.lang].name : ''
                            }}
                          </div>
                        </div>
                        <div class="confirm-product-card__item" :key="index + '2'">
                          <div class="confirm-product-card__item-name"
                               v-if="_.has(localProformTypes[itemType.id], 'typeName')"
                          >
                            {{
                            localProformTypes[itemType.id].typeName[itemType.translationStorage.en.name + '_En'].name
                            }}
                          </div>
                          <div class="confirm-product-card__item-val"
                               v-if="_.has(localProformTypes[itemType.id], 'typeValue')"
                          >
                            {{
                            localProformTypes[itemType.id].typeValue !== '' ?
                            localProformTypes[itemType.id].typeValue.value.translationStorage.en.name : ''
                            }}
                          </div>
                        </div>
                      </template>
                    </template>
                  </div>

                  <div class="confirm-product-card__item"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate([
                      'express_Price',
                    ])"></div>
                    <div class="confirm-product-card__item-name">
                      {{ $t('express_Price.localization_value.value') }}
                    </div>
                    <div class="confirm-product-card__item-val">
                      {{ item.product.price }}
                    </div>
                  </div>

                  <div class="confirm-product-card__item"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate([
                      'express_Quantity',
                    ])"></div>
                    <div class="confirm-product-card__item-name">
                      {{ $t('express_Quantity.localization_value.value') }}
                    </div>
                    <div class="confirm-product-card__item-val">
                      {{ item.product.itemsQuantity }}
                    </div>
                  </div>

                  <div class="confirm-product-card__bottom" v-if="item.product.handMade"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate([
                          'product_Handmade',
                        ])"></div>
                    <StatusIcoBtn :type="'handmade'" class="mr-2"/>
                    {{ $t('product_Handmade.localization_value.value') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="block-table__total site-line-total mt-0 mb-4"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                      'common_TotallyPerOrder',
                    ])"></div>
              {{ $t('common_TotallyPerOrder.localization_value.value') }}
              <span>${{ calculatePrice }}</span>
            </div>

            <template v-if="ex.express.type === FEDEX_DELIVERY_SERVICE.id">
              <div class="order-create__section-label section-label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                    'express_DocumentsINeed',
                  ])"></div>
                {{ $t('express_DocumentsINeed.localization_value.value') }}
              </div>

              <div class="custom-row ">
                <div class="custom-col custom-col--33 d-flex align-items-center "
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate([
                      'express_ToxicSubstance',
                      'express_NeededForSuch',
                    ])"></div>
                  <DefaultCheckbox
                          v-model="ex.express.toxicSubstanceControlActCert"
                          :disabled="true"
                          :label="$t('express_ToxicSubstance.localization_value.value')"
                          @click="ex.express.toxicSubstanceControlActCert = !ex.express.toxicSubstanceControlActCert"
                  />
                </div>
                <div class="custom-col custom-col--33 d-flex align-items-center "
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate([
                      'express_DropBallTest',
                    ])"></div>
                  <DefaultCheckbox
                          v-model="ex.express.dropBallTestCert"
                          :disabled="true"
                          :label="$t('express_DropBallTest.localization_value.value')"
                          @click="ex.express.dropBallTestCert = !ex.express.dropBallTestCert"
                  />
                </div>
              </div>
              <div class="custom-row ">
                <div class="order-create__col custom-col"
                     v-if="Object.keys(ex.express.additionFiles.data.downloadFiles).length > 0"
                >
                  <div class="custom-row product-img-row">
                    <div class="custom-col custom-col--16 custom-col--md-33 custom-col--sm-50"
                         v-for="(item, index) in ex.express.additionFiles.data.downloadFiles"
                         :key="index+'file'">
                      <DownloadFileBlock
                          :file="item"
                          :optionDelete="false"
                      />
                    </div>
                  </div>
                </div>
              </div>

            </template>

            <div class="order-create__title-horizontal"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
							'ex_tabsProductsDetail',
						])"></div>
              <TitleHorizontal
                      :value="$t('ex_tabsProductsDetail.localization_value.value')"
              />
            </div>

            <div class="order-create__row custom-row">
              <div class="order-create__col custom-col">
                <div class="order-create__table block-table">
                  <div class="block-table__name"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate([
							'ex_personalInformation',
						])"></div>
                    {{ $t('ex_personalInformation.localization_value.value') }}
                  </div>
                  <div class="block-table__row">
                    <div class="block-table__col block-table__col--20">
                      <div class="block-table__label"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate([
							'express_ContactName',
						])"></div>
                        {{ $t('express_ContactName.localization_value.value') }}
                      </div>
                      <div class="block-table__content">
                        {{ ex.express.personal.information.personalName }}
                      </div>
                    </div>
<!--                    <div class="block-table__col block-table__col&#45;&#45;20 mb-0"></div>-->
                    <div class="block-table__col block-table__col--20">
                      <div class="block-table__label"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate([
							'common_phone',
						])"></div>
                        {{ $t('common_phone.localization_value.value') }}
                      </div>
                      <div class="block-table__content">
                        {{ ex.express.personal.information.personalPhone }}
                      </div>
                    </div>
                    <div class="block-table__col block-table__col--20">
                      <div class="block-table__label"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate([
							'express_Email',
						])"></div>
                        {{ $t('express_Email.localization_value.value') }}
                      </div>
                      <div class="block-table__content">
                        {{ ex.express.personal.information.personalEmail }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div class="order-create__row custom-row">
              <div class="order-create__col custom-col">
                <div class="order-create__table block-table">
                  <div class="block-table__name"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate([
							'ex_deliveryAddress',
						])"></div>
                    {{ $t('ex_deliveryAddress.localization_value.value') }}
                  </div>
                  <div class="block-table__row">
                    <div class="block-table__col block-table__col--20">
                      <div class="block-table__label"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate([
							'common_address',
						])"></div>
                        {{ $t('common_address.localization_value.value') }}
                      </div>
                      <div class="block-table__content">
                        {{ ex.express.delivery.delivery.address }}
                      </div>
                    </div>
                    <div class="block-table__col block-table__col--20"
                         v-if="ex.express.delivery.delivery.address2 !== ''"
                    >
                      <div class="block-table__label"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate([
                          'common_addressSecond',
                        ])"></div>
                        {{$t('common_addressSecond.localization_value.value')}}
                      </div>
                      <div class="block-table__content">
                        {{ex.express.delivery.delivery.address2}}
                      </div>
                    </div>
                    <div class="block-table__col block-table__col--20">
                      <div class="block-table__label"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate([
							'common_city',
						])"></div>
                        {{ $t('common_city.localization_value.value') }}
                      </div>
                      <div class="block-table__content">
                        {{ ex.express.delivery.delivery.city }}
                      </div>
                    </div>
                    <div class="block-table__col block-table__col--20">
                      <div class="block-table__label"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate([
							'common_region',
						])"></div>
                        {{ $t('common_region.localization_value.value') }}
                      </div>
                      <div class="block-table__content">
                        {{ ex.express.delivery.delivery.region }}
                      </div>
                    </div>
                    <div class="block-table__col block-table__col--20">
                      <div class="block-table__label"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate([
							'common_country',
						])"></div>
                        {{ $t('common_country.localization_value.value') }}
                      </div>
                      <div class="block-table__content">
                        {{ ex.express.delivery.delivery.country.label }}
                      </div>
                    </div>
                    <div class="block-table__col block-table__col--20"
                         v-bind:class="{'mt-3': ex.express.delivery.delivery.address2 !== ''}"
                    >
                      <div class="block-table__label"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate([
							'common_zipCode',
						])"></div>
                        {{ $t('common_zipCode.localization_value.value') }}
                      </div>
                      <div class="block-table__content">
                        {{ ex.express.delivery.delivery.zip }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div class="order-create__row custom-row">
              <div class="order-create__col custom-col">
                <div class="order-create__table block-table">
                  <div class="block-table__name"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate([
                        'express_DeliveryDate',
                      ])"></div>
                    {{ $t('express_DeliveryDate.localization_value.value') }}
                  </div>
                  <div class="block-table__row">
                    <div class="block-table__col block-table__col--20">
                      <div class="block-table__label"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate([
                          'common_date',
                        ])"></div>
                        {{ $t('common_date.localization_value.value') }}
                      </div>
                      <div class="block-table__content">
                        {{ ex.express.deliveryDate | moment("DD MMM, YYYY") }}
                      </div>
                    </div>

                    <div class="block-table__col block-table__col--20">
                      <div class="block-table__label"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate([
                          'express_TrackingNumber',
                        ])"></div>
                        {{ $t('express_TrackingNumber.localization_value.value') }}
                      </div>
                      <div class="block-table__content">
                        <ValueHelper
                                :value="ex.express.systemTrackingNumber"
                        />
                      </div>
                    </div>

                    <div class="block-table__col block-table__col--20">
                      <div class="block-table__label"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate([
                          'express_TrackingNumberNP',
                          'express_TrackingNumberCDEK',
                        ])"></div>
<!--                        <template v-if="isUkrainianByUserData(getUserContactDataFromUser(ex.express.user).country.country_group)">-->
                          {{ $t('express_TrackingNumberNP.localization_value.value') }}
<!--                        </template>-->
<!--                        <template v-else>-->
<!--                          {{ $t('express_TrackingNumberCDEK.localization_value.value') }}-->
<!--                        </template>-->
                      </div>
                      <div class="block-table__content">
                        <ValueHelper
                                :value="ex.express.trackingNumber"
                        />
                      </div>
                    </div>

                    <div class="block-table__col block-table__col--20">
                      <div class="block-table__label"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate([
                          'express_LabelPrice',
                        ])"></div>
                        {{ $t('express_LabelPrice.localization_value.value') }}
                      </div>
                      <div class="block-table__content">
                        <ValueHelper
                                :value="ex.express.shippingCost"
                        />
                      </div>
                    </div>
                    <div class="block-table__col block-table__col--20">
                      <div class="block-table__label"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate([
                          'express_feeAmount',
                        ])"></div>
                        {{ $t('express_feeAmount.localization_value.value') }}
                      </div>
                      <div class="block-table__content">
                        <ValueHelper
                                :value="ex.express.fee"
                        />
                      </div>
                    </div>

                  </div>

                  <div class="block-table__row">
                    <div class="block-table__col block-table__col--20">
                      <div class="block-table__label"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate([
                          'express_transactionNum',
                        ])"></div>
                        {{ $t('express_transactionNum.localization_value.value') }}
                      </div>
                      <div class="block-table__content">
                        <router-link class="table-link btn-style"
                                     v-if="ex.express.transactionNum"
                                     :to="`${$store.getters.GET_PATHS.cusPayments}?page=1&count=25&type=${ex.express.transactionPaymentSystem}&transactionNumber=${ex.express.transactionNum}`">
                          <ValueHelper
                                  :value="ex.express.transactionNum"
                          />
                        </router-link>
                        <span v-else>—</span>
                      </div>
                    </div>

                    <div class="block-table__col block-table__col--20">
                      <div class="block-table__label"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate([
                          'express_orderPrice',
                        ])"></div>
                        {{ $t('express_orderPrice.localization_value.value') }}
                      </div>
                      <div class="block-table__content">
                        <ValueHelper
                            :value="ex.express.orderPrice"
                        />
                      </div>
                    </div>

                    <div class="block-table__col block-table__col--20">
                      <div class="block-table__label"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate([
                          'express_FreightCost',
                        ])"></div>
                        {{$t('express_FreightCost.localization_value.value')}}
                      </div>
                      <div class="block-table__content">
                        <ValueHelper
                            :value="ex.express.freightCost"
                        />
                      </div>
                    </div>

                    <div class="block-table__col block-table__col--20" v-if="ex.express.recipientTax.length">
                      <div class="block-table__label"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        Mexican Tax ID / National ID
                      </div>
                      <div class="block-table__content">
                        <ValueHelper
                            :value="ex.express.recipientTax"
                        />
                      </div>
                    </div>

                    <div class="block-table__col block-table__col--20" v-if="ex.express.visibleVatNumber">
                      <div class="block-table__label"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        {{$t('common_vatNumber.localization_value.value')}}
                      </div>
                      <div class="block-table__content">
                        <ValueHelper
                            v-if="ex.express.visibleVatNumber"
                            :value="ex.express.vatNumber"
                        />
                        <ValueHelper
                            v-else
                            :value="'—'"
                        />
                      </div>
                    </div>

                    <template
                            v-if="ex.express.type === TNT_DELIVERY_SERVICE.id && ex.express.sendType === ex.express.sendTypes.sender">

                      <!--<div class="block-table__col block-table__col&#45;&#45;20 mb-0"></div>-->

                      <div class="block-table__col block-table__col--20">
                        <div class="block-table__label" v-if="!ex.express.lunchBreak"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate([
                            'express_TimeGap',
                          ])"></div>
                          {{ $t('express_TimeGap.localization_value.value') }}
                        </div>
                        <div class="block-table__label" v-if="ex.express.lunchBreak"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate([
                            'express_BeforeLunch',
                          ])"></div>
                          {{ $t('express_BeforeLunch.localization_value.value') }}
                        </div>
                        <div class="block-table__content">
                          {{ ex.express.timeCourierTo | moment("hh:mm") }} —
                          {{ ex.express.timeCourierFrom | moment("hh:mm") }}
                        </div>
                      </div>

                      <div class="block-table__col block-table__col--20"
                           v-if="ex.express.lunchBreak"
                      >
                        <div class="block-table__label"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate([
                              'express_AfterLunch',
                            ])"></div>
                          {{ $t('express_AfterLunch.localization_value.value') }}
                        </div>
                        <div class="block-table__content">
                          {{ ex.express.timeNotAvailableTo | moment("hh:mm") }} —
                          {{ ex.express.timeNotAvailableFrom | moment("hh:mm") }}
                        </div>
                      </div>
                    </template>


                  </div>
                </div>
              </div>
            </div>

            <div class="order-create__title-horizontal"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
										'ex_packaging',
									])"></div>
              <TitleHorizontal
                      :value="$t('ex_packaging.localization_value.value')"
              />
            </div>

            <div class="order-create__row custom-row">
              <div class="order-create__col custom-col">
                <div class="order-create__table block-table">
                  <div class="block-table__row">
                    <div class="block-table__col block-table__col--40">
                      <div class="block-table__label"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate([
										'packaging_NamePackage',
									])"></div>
                        {{ $t('packaging_NamePackage.localization_value.value') }}
                      </div>
                      <div class="block-table__content">
                        {{ ex.express.packaging.packaging.name.package_name }}
                      </div>
                    </div>

                    <div class="block-table__col block-table__col--fit">
                      <div class="block-table__label"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate([
										'packaging_Weight',
									])"></div>
                        {{ $t('packaging_Weight.localization_value.value') }}
                      </div>
                      <div class="block-table__content">
                        {{ ex.express.packaging.packaging.weight }}kg
                      </div>
                    </div>
                    <div class="block-table__col block-table__col--fit">
                      <div class="block-table__label"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate([
										'packaging_Height',
									])"></div>
                        {{ $t('packaging_Height.localization_value.value') }}
                      </div>
                      <div class="block-table__content">
                        {{ ex.express.packaging.packaging.height }}cm
                      </div>
                    </div>
                    <div class="block-table__col block-table__col--fit">
                      <div class="block-table__label"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate([
										'packaging_Width',
									])"></div>
                        {{ $t('packaging_Width.localization_value.value') }}
                      </div>
                      <div class="block-table__content">
                        {{ ex.express.packaging.packaging.width }}cm
                      </div>
                    </div>
                    <div class="block-table__col block-table__col--fit">
                      <div class="block-table__label"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate([
										'packaging_Length',
									])"></div>
                        {{ $t('packaging_Length.localization_value.value') }}
                      </div>
                      <div class="block-table__content">
                        {{ ex.express.packaging.packaging['length'] }}cm
                      </div>
                    </div>
                    <div class="block-table__col block-table__col--fit">
                      <div class="block-table__label"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate([
										'express_Volume',
									])"></div>
                        {{ $t('express_Volume.localization_value.value') }}
                      </div>
                      <div class="block-table__content">
                        {{ ex.express.packaging.packaging.volumeWeight }}kg
                      </div>
                    </div>

                    <div class="block-table__col block-table__col--fit" v-if="ex.express.fedexPackingType !== ''">
                      <div class="block-table__label">
                        {{$t('express_fedexPacking.localization_value.value')}}
                      </div>
                      <div class="block-table__content">
                        {{$t(`${ex.express.fedexPackingType.translation}.localization_value.value`)}}
                      </div>
                    </div>
                  </div>



                  <div class="block-table__row"
                       v-if="ex.express.packaging.packaging.insurance"
                  >
                    <div class="block-table__col block-table__col--40">
                      <div class="block-table__content mt-3"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate([
										'express_INeedInsurance',
									])"></div>
                        <DefaultCheckbox
                                :label="$t('express_INeedInsurance.localization_value.value') + ' — $' + ex.express.packaging.packaging.insurancePrice"
                                :value="true"
                                :disabled="true"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </template>
        <template slot="footer">
          <div class="order-create__footer" v-if="!isEasyType">
            <div class="order-create__footer-link"
                 v-if="(!this.isWaitForPayStatus(ex.express.expressStatus) && (!this.waitingMakingLabelStatus(ex.express.expressStatus) || this.isAdmin)) &&
                 (ex.express.expressStatus !== EXPRESS_DELIVERY_STATUSES.COMPLETED_STATUS || this.isAdmin)"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
										'importO_deleteOrder',
									])"></div>
              <span class="site-link site-link--alt"
                    @click="removeExpress(ex.express.expressObj.expressOrderInstance.id)"
              >
                {{ $t('importO_deleteOrder.localization_value.value') }}
              </span>

            </div>

            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
										'common_CreateCopy',
									])"></div>

              <MainButton
                      class="order-create__footer-btn-i wfc"
                      :value="$t('common_CreateCopy.localization_value.value')"
                      @click.native="copyExpress(ex.express.expressObj.expressOrderInstance.id)"
              />
            </div>
          </div>
        </template>
      </CardRightBlock>

    </div>

    <CancelOrderCreation
            @close="closeCancelOrderCreation"
            v-if="isModalCancelOrderCreation"
    />

    <StatusOrderDetailsPopup
            @close="closeStatusOrderDetailsPopup"
            v-if="isModalStatusOrderDetailsPopup"
    />
  </div>
</template>

<script>
  import CardLeftBlock from "../../../../../../components/coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import MainButton from "../../../../../../components/UI/buttons/MainButton/MainButton";
  import PayPalIcon from "../../../../../../../public/img/company-icons-group/paypal-icon.svg?inline";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack.vue";
  // import ManagerButton from "../../../../../UI/buttons/ManagerButton/ManagerButton";
  // import LinkButton from "../../../../../UI/buttons/LinkButton/LinkButton";
  import CancelOrderCreation from "../../../popups/CancelOrderCreation/CancelOrderCreation.vue";
  import TitleHorizontal from "../../../../../coreComponents/TitleHorizontal/TitleHorizontal";
  import DefaultCheckbox from "../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import StatusIcoBtn from "../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import StatusOrderDetailsPopup from "../../../popups/StatusOrderDetailsPopup/StatusOrderDetailsPopup";
  import {mixinDetictingMobile} from "../../../../../../mixins/mobileFunctions";
  import {
    DHL_DELIVERY_SERVICE,
    EXPRESS_DELIVERY_STATUSES,
    FEDEX_DELIVERY_SERVICE, NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE,
    TNT_DELIVERY_SERVICE, UPS_DELIVERY_SERVICE
  } from "../../../../../../staticData/staticVariables";
  import {expressMixins, expressMixinsFromCard} from "../../../../../../mixins/expressMixins/expressMixin";
  import ValueHelper from "../../../../../coreComponents/ValueHelper/ValueHelper";
  import {proformMixins} from "../../../../../../mixins/proformMixins/proformMixins";
  import {expressUserContactsMixin} from "@/mixins/expressMixins/expressUserContactsMixin";
  import DownloadFileBlock from "@/components/coreComponents/DownloadFileBlock/DownloadFileBlock";


  export default {
    name: "ExpressOrderShowUser",

    components: {
      DownloadFileBlock,
      ValueHelper,
      CardLeftBlock,
      MainButton,
      PayPalIcon,
      CardRightBlock,
      LinkBack,
      // ManagerButton,
      // LinkButton,
      CancelOrderCreation,
      TitleHorizontal,
      DefaultCheckbox,
      StatusIcoBtn,
      StatusOrderDetailsPopup,
    },

    mixins: [mixinDetictingMobile, expressMixins, proformMixins, expressUserContactsMixin, expressMixinsFromCard],

    props: {
      ex: Object,
    },

    data() {
      return {
        EXPRESS_DELIVERY_STATUSES: EXPRESS_DELIVERY_STATUSES,
        TNT_DELIVERY_SERVICE: TNT_DELIVERY_SERVICE,
        DHL_DELIVERY_SERVICE: DHL_DELIVERY_SERVICE,
        FEDEX_DELIVERY_SERVICE: FEDEX_DELIVERY_SERVICE,
        NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE: NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE,
        UPS_DELIVERY_SERVICE: UPS_DELIVERY_SERVICE,
        isModalCancelOrderCreation: false,
        isModalStatusOrderDetailsPopup: false,
      }
    },

    methods: {



      showCancelOrderCreation() {
        this.isModalCancelOrderCreation = true
      },

      closeCancelOrderCreation() {
        this.isModalCancelOrderCreation = false
      },

      editTest() {
        console.log('function editTest');
      },


      showStatusOrderDetailsPopup() {
        this.isModalStatusOrderDetailsPopup = true
      },

      closeStatusOrderDetailsPopup() {
        this.isModalStatusOrderDetailsPopup = false
      },


    }
  }
</script>

<style scoped>

</style>

