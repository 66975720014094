<template>
  <div class="detail-page">
    <div class="detail-page__left"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'express_NewExpressOrder',
          'express_PayPalTransaction',
        ])"></div>
      <CardLeftBlock>
        <template slot="button">
          <h2 class="mt-0">{{$t('express_NewExpressOrder.localization_value.value')}}</h2>
          <div class="card-detail-left__date">
            {{ new Date() | moment("DD MMM, YYYY") }}
          </div>
          <router-link :to="$store.getters.GET_PATHS.cusPayments + '?order=10382'"
                       v-if="ex.express.typeTransaction === 'payment'"
          >
            <MainButton
                    :ico="true"
                    :value="$t('express_PayPalTransaction.localization_value.value')"
                    class="card-detail-left__btn"
            >
              <template slot="ico">
                <PayPalIcon/>
              </template>
            </MainButton>
          </router-link>

        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                  'ex_backTo',
                ])"></div>
                <span class="order-create__head-back">
                  <LinkBack
                          :value="$t('ex_backTo.localization_value.value')"
                          @click.native="goRouterBack()"
                  />
                </span>
              </div>
            </div>

            <div
                    v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                  'express_NewExpressOrder',
                ])"></div>
              <div class="order-create__head-title color-orange"
                   v-if="ex.express.type === TNT_DELIVERY_SERVICE.id">
                {{$t('express_NewExpressOrder.localization_value.value')}} (TNT)
              </div>
              <div class="order-create__head-title color-red"
                   v-if="ex.express.type === DHL_DELIVERY_SERVICE.id">
                {{$t('express_NewExpressOrder.localization_value.value')}} (DHL)
              </div>
              <div class="order-create__head-title color-blue"
                   v-if="ex.express.type === FEDEX_DELIVERY_SERVICE.id">
                <template v-if="isEasyType">{{$t('easy_express.localization_value.value')}}</template>
                <template v-else>{{$t('express_NewExpressOrder.localization_value.value')}} (FedEx)</template>
              </div>
              <div class="order-create__head-title color-blue"
                   v-if="ex.express.type === NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id">
                {{$t('express_NewExpressOrder.localization_value.value')}} (Nova poshta)
              </div>
              <div class="order-create__head-title color-blue"
                   v-if="ex.express.type === UPS_DELIVERY_SERVICE.id">
                {{$t('express_NewExpressOrder.localization_value.value')}} (UPS)
              </div>

              <div class="order-create__head-txt"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                  'ex_pleaseFill',
                ])"></div>
                {{$t('ex_pleaseFill.localization_value.value')}}
              </div>
            </div>


            <div
                    v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                'express_PikupInfo',
                'express_ProductsDetails',
                'express_DeliveryDetails',
                'express_Packaging',
                'ex_tabsConfirmation',
              ])"></div>
              <div class="order-create__head-steps"
                   v-if="$store.getters.getIsAdminRights === 'user'">
                <ProgressSteps
                        :options="progressStepsSetting"
                        :step="activeStep"
                        @changeStep="stepChange"
                        @nextPageHandler="nextPageHandler"
                />
              </div>
            </div>
          </div>
        </template>

        <template slot="body">
          <div class="order-create__section">
            <div class="custom-row" v-if="$store.getters.getIsAdminRights === 'admin'">
              <div class="custom-col custom-col--33 custom-col--md-100">
                <UserSelect
                        :serverError="serverError"
                        :validationUser="ex.validation.user"
                        :validationTxtUser="ex.validationTxt.user"
                        :validationTranslateUser="ex.validationTranslate.user"
                        :userSelected="ex.express.user"
                        @changeUser="changeUser"
                        :typeSelect="'users'"
                        :userEmail="true"
                />
              </div>
            </div>
          </div>

          <div class="order-create__section"
               v-if="activeStep == 1 || $store.getters.getIsAdminRights === 'admin'">

            <div class="order-create__section">
              <div class="order-create__section-label section-label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['ex_sendFrom',])"></div>
                {{ $t('ex_sendFrom.localization_value.value') }}
              </div>
              <PickupInfo
                  :ex="ex"
              />
              <NovaPoshtaSender
                  v-if="ex.express.type === NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id"
                  :ex="ex"
              />
            </div>
          </div>
          <div class="order-create__section"
               v-if="activeStep == 2 || $store.getters.getIsAdminRights === 'admin'">
            <DeliveryDetails
                    :ex="ex"
            />
          </div>
          <div class="order-create__section"
               v-if="activeStep == 3 || $store.getters.getIsAdminRights === 'admin'">
            <ProductsDetails
                    :ex="ex"
                    @changeCategory="({val, nameVal, item}) => changeCategoryProform({val, nameVal, item}, ex.express.delivery.delivery.country, true)"
                    @addProduct="$emit('addProduct')"
                    @removeProduct="i => $emit('removeProduct', i)"
            />
          </div>
          <div class="order-create__section"
               v-if="activeStep == 4 || $store.getters.getIsAdminRights === 'admin'">
            <Packaging
                    :ex="ex"
            />
          </div>
          <div class="order-create__section"
               v-if="activeStep == 5 || $store.getters.getIsAdminRights === 'admin'"
          >
            <Confirmation
                    v-if="activeStep == 5 && $store.getters.getIsAdminRights !== 'admin'"
                    :ex="ex"
                    @changeStep="step => {activeStep = step}"
            />

            <div class="order-create__title-horizontal"
                 v-if="Object.keys(labelPrice).length > 0 || $store.getters.getExpressLoadingPrice"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
										'express_Shipping',
									])"></div>
              <TitleHorizontal
                      :value="$t('express_Shipping.localization_value.value')"
              />
            </div>

            <div class="pt-2 pb-3" v-if="$store.getters.getExpressLoadingPrice">
              <DotsShadowPreloader/>
            </div>

            <template v-if="Object.keys(labelPrice).length > 0 && !$store.getters.getExpressLoadingPrice">
              <div class="date-time"
                   v-if="_.has(labelPrice, 'estimated_time_of_arrival') && labelPrice.estimated_time_of_arrival"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['express_EstimatedTimeOfArrival',])"></div>
                {{$t('express_EstimatedTimeOfArrival.localization_value.value')}}
                {{labelPrice.estimated_time_of_arrival | moment("DD/MM/YYYY H:mm:ss")}}
              </div>

              <LabelPrice
                      :labelPrice="labelPrice"
                      :bigCards="true"
                      :totalShippingCostTranslate="ex.express.type === FEDEX_DELIVERY_SERVICE.id ? 'common_TotalShippingCostExpress' : 'common_TotalShippingCost'"
                      :currentVariant="ex.express.variantLabelPrice"
                      :underTotalTxt="ex.express.type === NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id || ex.express.type === FEDEX_DELIVERY_SERVICE.id ? 'express_periodDocuments' : null"
                      @changeVariant="changeVariant"
              />
                      <!--:costDelivery="ex.express.type !== FEDEX_DELIVERY_SERVICE.id"-->


            </template>


          </div>
        </template>
        <template slot="footer">
          <div class="order-create__footer">
            <div class="order-create__footer-link"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                  'common_cancel',
                  'common_Previous',
                ])"></div>
              <router-link :to="$store.getters.GET_PATHS.easyOrders"
                           class="site-link site-link--alt"
                           v-if="activeStep == 1 && isEasyType"
              >
                {{ $t('common_cancel.localization_value.value') }}
              </router-link>
              <router-link :to="$store.getters.GET_PATHS.expressOrders"
                           class="site-link site-link--alt"
                           v-else-if="activeStep == 1"
              >
                {{$t('common_cancel.localization_value.value')}}
              </router-link>
              <span class="site-link site-link--alt"
                    @click="prevPageHandler()"
                    v-if="activeStep != 1"
              >
                {{$t('common_Previous.localization_value.value')}}
              </span>
            </div>

            <div class="order-create__footer-btn ml-auto"
                 v-if="Object.keys($store.getters.getUserProfile).length > 0"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                  'common_SaveInDrafts',
                  'common_drafts',
                  'common_savingInDrafts',
                  'common_Next',
                  'express_BuyLabel',
                  'express_ConfirmBuyLabel',
                ])"></div>
              <MainButton
                      class="order-create__footer-btn-i secondary"
                      :value="$t('common_SaveInDrafts.localization_value.value')"
                      :tooltip="true"
                      @click.native="showSaveInDraftsPopup"
                      v-if="activeStep == 5 || $store.getters.getIsAdminRights === 'admin'"
              >
                <template slot="tooltip">
                  <p>
                    <b>{{$t('common_drafts.localization_value.value')}}</b>
                  </p>
                  <p>
                    {{$t('common_savingInDrafts.localization_value.value')}}
                  </p>
                </template>
              </MainButton>

              <MainButton
                      class="order-create__footer-btn-i"
                      :value="$t('common_Next.localization_value.value')"
                      @click.native="nextPageHandler()"
                      v-if="activeStep !== progressStepsSetting.length && $store.getters.getIsAdminRights === 'user'"
              />

              <MainButton
                      v-else-if="!hasCreateLabel"
                      class="order-create__footer-btn-i wfc"
                      :value="isUserHasEasyOrder ? $t('common_Next.localization_value.value') : $t('express_BuyLabel.localization_value.value')"
                      @click.native="getLabelPriceFromCard"
                      v-bind:class="{'disabled-btn' : $store.getters.getExpressBtn}"
              />

              <MainButton
                      v-else-if="hasCreateLabel &&
                        (Object.keys(labelPrice).length > 0 && parseFloat(labelPrice.price) !== 0)"
                      class="order-create__footer-btn-i wfc"
                      :value="(ex.express.typeTransaction !== '' && ex.express.typeTransactionId !== '' && ex.express.orderPrice !== '') ?
                          $t('express_CreateLabel.localization_value.value') :
                          $t('express_ConfirmBuyLabel.localization_value.value')"
                      @click.native="$emit('createLabel', $route.params.id)"
                      v-bind:class="{'disabled-btn' : $store.getters.getExpressBtn}"
              />
              <!--@click.native="showNoMoneyPopup"-->
            </div>
          </div>
        </template>
      </CardRightBlock>

    </div>

    <NoMoneyPopup
            @close="closeNoMoneyPopup"
            v-if="isModalNoMoneyPopup"
    />

    <CancelOrderCreation
            @close="closeCancelOrderCreation"
            v-if="isModalCancelOrderCreation"
    />

    <SaveInDraftsPopup
            @close="closeSaveInDraftsPopup"
            v-if="isModalSaveInDraftsPopup"
            @yes="saveInDraft"
    />

  </div>
</template>

<script>
  import CardLeftBlock from "../../../../../../components/coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import MainButton from "../../../../../../components/UI/buttons/MainButton/MainButton";
  import PayPalIcon from "../../../../../../../public/img/company-icons-group/paypal-icon.svg?inline";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack.vue";
  import ProgressSteps from '../../../../../UI/progress/ProgressSteps/ProgressSteps.vue'
  // import ManagerButton from "../../../../../UI/buttons/ManagerButton/ManagerButton";
  // import LinkButton from "../../../../../UI/buttons/LinkButton/LinkButton";
  import Confirmation from "../../chunks/Confirmation/Confirmation.vue";
  import NoMoneyPopup from "../../../popups/NoMoneyPopup/NoMoneyPopup";
  import CancelOrderCreation from "../../../popups/CancelOrderCreation/CancelOrderCreation.vue";
  import {proformMixins} from "../../../../../../mixins/proformMixins/proformMixins";
  import {
    AUSTRALIA_ID, CANADA_COUNTRY_ID,
    DHL_DELIVERY_SERVICE,
    FEDEX_DELIVERY_SERVICE, MEXICO_COUNTRY_ID, NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE,
    TNT_DELIVERY_SERVICE, UPS_DELIVERY_SERVICE,
    USA_COUNTRY_ID,
  } from "../../../../../../staticData/staticVariables";
  import UserSelect from "../../../../../coreComponents/UserSelect/UserSelect";
  import SaveInDraftsPopup from "../../../../../coreComponents/Popups/SaveInDraftsPopup/SaveInDraftsPopup.vue";
  import {tabsMixins} from "../../../../../../mixins/creationPageMixins/tabsMixins";
  import {expressMixinsFromCard} from "../../../../../../mixins/expressMixins/expressMixin";
  import TitleHorizontal from "../../../../../coreComponents/TitleHorizontal/TitleHorizontal";
  import LabelPrice from "../../../../../coreComponents/LabelPrice/LabelPrice";
  import DotsShadowPreloader from "../../../../../coreComponents/preloaders/DotsShadowPreloader/DotsShadowPreloader";
  import {hsCodeMixin} from "../../../../../../mixins/commonMixins/hsCodeMixin";
  import {vatNumberMixin} from "../../../../../../mixins/commonMixins/vatNumberMixin";
  import Packaging from "@/components/modules/ExpressOrdersModule/components/chunks/UserBlocks/Packaging/Packaging";
  import PickupInfo from "@/components/modules/ExpressOrdersModule/components/chunks/UserBlocks/PickupInfo/PickupInfo";
  import ProductsDetails
    from "@/components/modules/ExpressOrdersModule/components/chunks/UserBlocks/ProductsDetails/ProductsDetails";
  import DeliveryDetails
    from "@/components/modules/ExpressOrdersModule/components/chunks/UserBlocks/DeliveryDetails/DeliveryDetails";
  import NovaPoshtaSender
    from "@/components/modules/ExpressOrdersModule/components/chunks/UserBlocks/NovaPoshtaSender/NovaPoshtaSender";


  export default {
    name: "ExpressOrderCreationUser",

    components: {
      NovaPoshtaSender,
      DotsShadowPreloader,
      LabelPrice,
      TitleHorizontal,
      UserSelect,
      CardLeftBlock,
      MainButton,
      PayPalIcon,
      CardRightBlock,
      LinkBack,
      ProgressSteps,
      // ManagerButton,
      // LinkButton,
      PickupInfo,
      ProductsDetails,
      DeliveryDetails,
      Packaging,
      Confirmation,
      NoMoneyPopup,
      CancelOrderCreation,
      SaveInDraftsPopup,
    },

    mixins: [
      proformMixins,
      hsCodeMixin,
      vatNumberMixin,
      tabsMixins,
      expressMixinsFromCard
    ],

    props: {
      ex: Object,
      selectedCountry: {String, Object},
      // hasCreateLabel: Boolean,
    },

    watch: {
      selectedCountry: function(country){
        if(!country?.id) return

        this.$store.dispatch('getCountryDataById', country.id).then(async () => {
          let countryWithCountryGroups = this.$store.getters.getCountryGroup

          if (this.ex.express.type === DHL_DELIVERY_SERVICE.id) {
            this.ex.setVisibleFreightCost(true)
          }
          if (country.id === USA_COUNTRY_ID || country.id === AUSTRALIA_ID || country.id === CANADA_COUNTRY_ID || country.id === MEXICO_COUNTRY_ID) {
            this.$store.dispatch('getRegionsByCountryExpress', {id: country.id, url: '?orderExpress=1'}).then(() => {
              this.setExpressRegion(this.$store.getters.getRegions)
            }).catch(error => this.createErrorLog(error))
          }
          if (this.ex.express.type === DHL_DELIVERY_SERVICE.id || this.ex.express.type === FEDEX_DELIVERY_SERVICE.id) {
            this.ex.setVisibleHSCode(true)
          }
          else {
            this.ex.setVisibleHSCode(this.getHSCodePermissionsByCountry(countryWithCountryGroups, this.ex.express.type === FEDEX_DELIVERY_SERVICE.id))
          }

          let needVat = await this.getExpressVatNumber(country, Number(this.ex.express.type))
          this.ex.setVisibleAndIsVatNumber(needVat)
          // this.ex.setVisibleAndIsVatNumber(this.getVatNumberPermissionsByCountry(countryWithCountryGroups, this.ex.express.type === FEDEX_DELIVERY_SERVICE.id))

        }).catch(error => this.createErrorLog(error))
      },

      activeStep: function () {
        this.hasCreateLabel = false
      },

      labelPrice: function () {
        this.hasCreateLabel = true
        setTimeout(() => {
          document.getElementsByClassName('order-create__total')[0]
            .scrollIntoView({block: "center", behavior: "smooth"});
        },50)
      },
    },



    data() {
      return {
        progressStepsSetting: [
          this.$t('express_PikupInfo.localization_value.value'),
          this.$t('express_DeliveryDetails.localization_value.value'),
          this.$t('express_ProductsDetails.localization_value.value'),
          this.$t('express_Packaging.localization_value.value'),
          this.$t('ex_tabsConfirmation.localization_value.value'),
        ],

        TNT_DELIVERY_SERVICE: TNT_DELIVERY_SERVICE,
        DHL_DELIVERY_SERVICE: DHL_DELIVERY_SERVICE,
        FEDEX_DELIVERY_SERVICE: FEDEX_DELIVERY_SERVICE,
        NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE: NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE,
        UPS_DELIVERY_SERVICE: UPS_DELIVERY_SERVICE,

        activeStep: 1,

        serverError: false,

        isModalNoMoneyPopup: false,
        isModalCancelOrderCreation: false,
        isModalSaveInDraftsPopup: false,

        labelPrice: {},
        hasCreateLabel: false,
      }
    },

    methods: {

      showNoMoneyPopup() {
        this.isModalNoMoneyPopup = true
      },

      closeNoMoneyPopup() {
        this.isModalNoMoneyPopup = false
      },

      showCancelOrderCreation() {
        this.isModalCancelOrderCreation = true
      },

      closeCancelOrderCreation() {
        this.isModalCancelOrderCreation = false
      },

      showSaveInDraftsPopup() {
        this.isModalSaveInDraftsPopup = true
      },

      closeSaveInDraftsPopup() {
        this.isModalSaveInDraftsPopup = false
      },


    }
  }
</script>

<style scoped lang="scss">
  .date-time{
    margin-bottom: 20px;
  }
</style>
