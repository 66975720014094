<template>
  <div class="fragment w-100">
    <div class="site-document d-flex justify-content-center"
         v-if="getCurrentFile.type === 'application/pdf'"
    >
      <div class="site-document__remove"
           v-if="optionDelete"
           @click="removeFile(file)">
        <CloseIcon/>
      </div>
      <div class="site-document__img"
           @click="openPdf(getCurrentFile['base64'])">
        <img src="/img/common/pdf_file_icon.svg" alt="img">
      </div>
    </div>

    <div class="site-document d-flex justify-content-center"
         v-else-if="getCurrentFile.type === 'application/octet-stream'"
    >
      <div class="site-document__remove"
           v-if="optionDelete"
           @click="removeFile(file)">
        <CloseIcon/>
      </div>
      <div class="site-document__img">
        <img v-if="customTypeFile === 'psd'" style="max-width: 100px; max-height: 70px;" alt="ico" src="/img/UI-group/psd.png"/>
        <img v-if="customTypeFile === 'dfx'" style="max-width: 100px; max-height: 70px;" alt="ico" src="/img/UI-group/dxf-file.png"/>
        <img v-if="customTypeFile === 'dst'" style="max-width: 100px; max-height: 70px;" alt="ico" src="/img/UI-group/dst-ico.png"/>
      </div>
    </div>

    <div class="site-document d-flex justify-content-center"
         v-else-if="getCurrentFile.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
    >
      <div class="site-document__remove"
           v-if="optionDelete"
           @click="removeFile(file)">
        <CloseIcon/>
      </div>
      <div class="site-document__img" @click="downloadDocx">
        <img style="max-width: 100px; max-height: 70px;" alt="ico" src="/img/UI-group/docx-ico.png"/>
      </div>
    </div>

    <div class="site-document d-flex justify-content-center"
         v-else-if="getCurrentFile.type === 'application/postscript'"
    >
      <div class="site-document__remove"
           v-if="optionDelete"
           @click="removeFile(file)">
        <CloseIcon/>
      </div>
      <div class="site-document__img">
        <img style="max-width: 100px; max-height: 70px;" alt="ico" src="/img/UI-group/ai.png"/>
      </div>
    </div>

    <div class="site-document d-flex justify-content-center"
         v-else
    >
      <div class="site-document__remove"
           v-if="optionDelete"
           @click="removeFile(file)">
        <CloseIcon/>
      </div>
      <div class="site-document__img">
        <img :src="`data:${getCurrentFile.type};base64,` + getCurrentFile['base64']"
             @click="showSingle(getCurrentFile.typeSize === 'small_image' ? parseInt(getCurrentFile.id) - 1 : getCurrentFile.id)"
             alt="img">
      </div>
    </div>

    <Lightbox
        escDisabled
        moveDisabled
        :visible="visible"
        :imgs="imgs"
        :index="index"
        @hide="handleHide"
    ></Lightbox>


  </div>
</template>

<script>
import CloseIcon from "../../../../public/img/modal-group/close-icon.svg?inline";
import Lightbox from "vue-easy-lightbox";
export default {
  name: "DownloadFileBlock",
  components:{
    CloseIcon,
    Lightbox,
  },

  props: {
    file: Object,
    type: {
      type: String,
      default: null
    },
    optionDelete: {
      type: Boolean,
      default: true
    },
    customTypeFile: {
      type: String,
      default: 'dfx'
    }
  },

  computed: {
    getCurrentFile(){

      if(this.file?.id) {
        return this.file
      }

      let key = Object.keys(this.file)
      return this.file[key]
    }
  },

  data(){
    return{
      images: -1,

      imgs: [], // Img Url , string or Array of string
      visible: false,
      index: 0, // default: 0
    }
  },

  mounted() {
    if(this.file){
      let key = Object.keys(this.file)
      this.currentFile = this.file[key]
    }
  },

  methods: {
    // openFile(file){
    //   if(file.type === 'application/pdf'){
    //     this.openPdf(file['base64'])
    //   }
    // },

    downloadDocx() {
      this.globalDownloadBase64File(this.getCurrentFile.type, this.getCurrentFile['base64'], 'Additional file.docx')
    },

    removeFile(file){
      let id = null

      if(file?.id) {
        id = file?.id
      } else {
        id = parseInt(this._.first(Object.keys(file)))
      }

      this.$emit('removeFile', id)
    },

    openPdf(base64) {
      this.globalOpenPdfBase64(base64)
    },

    handleHide() {
      this.visible = false
    },

    show() {
      this.visible = true
    },

    showSingle(id) {
      this.$store.dispatch('getFileFromServer', id).then((response) => {
        this.imgs = []
        this.imgs.push({
          title: 'img',
          src: `data:image/png;base64,` + response[id]
        })
        this.show()
      }).catch(error => this.createErrorLog(error))
    },

  }
}
</script>

<style scoped>
  .site-document__img{
    max-width: 100%;
  }
</style>
